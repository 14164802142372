import React from 'react'
import { Helmet } from 'react-helmet'

const Refund = () => {
    return (
        <>
            <Helmet>
                <title>
                    Refund And Return Policy - Your Trust Matters at ejobee
                </title>
            </Helmet>
            <section>
                <div className="container">
                    <div className="section-title mb-3">
                        <h4>Return and Refund Policy</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column gap-5">
                            <p>We do not offer any return or refund policy for our services, including premium plans for candidates and job posting plans. Once a premium plan is purchased or a job posting plan is activated, the associated services are made available immediately, and the fees are non-refundable.</p>

                            <p>
                                We encourage all users to carefully consider their choices before making a purchase or activating a plan on our platform. Our team is available to address any queries or concerns regarding our services to ensure that users have a clear understanding of what is being offered before making a commitment.
                            </p>

                            <p>
                                In the event of any technical issues or dissatisfaction with our services, we are committed to resolving the matter promptly and providing assistance to the best of our abilities
                            </p>

                            <p>
                                Thank you for choosing our platform for your job-seeking or recruiting needs.
                            </p>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Refund