import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { candidate_resend_otp, candidate_verify_otp, company_verifyotp } from '../../ApiServices';
import { toast } from 'react-toastify';

const Otp = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const type = new URLSearchParams(location.search).get('type');
    const mobile = new URLSearchParams(location.search).get('mobile');

    const [otp, setotp] = useState('');
    const [otperror, setotperror] = useState('');
    const [counter, setcounter] = useState(60);

    // counter
    useEffect(() => {
        let timer;
        if (counter > 0) {
            timer = setTimeout(() => setcounter(counter - 1), 1000);
        }

        return () => clearTimeout(timer);
    }, [counter]);



    useEffect(() => {
        const type = new URLSearchParams(location.search).get('type');
        const mobile = new URLSearchParams(location.search).get('mobile');
        if (mobile === '' || mobile === null || type == "" || type === null) {
            navigate('/login')
        }
    }, [])

    const otpverifybtn = () => {
        if (type == 'candidate') {
            candidate_verify_otp(mobile, otp).then((res) => {
                if (res.data.status == "success") {
                    localStorage.setItem('candidate_id', res.data.data.id);

                    res.data.data.is_profile_completed ? localStorage.setItem('candidate_is_profile_completed', res.data.data.is_profile_completed) : localStorage.setItem('candidate_is_profile_completed', 'false');
                    res.data.data.is_personal_detail ? localStorage.setItem('candidate_is_personal_details', res.data.data.is_personal_detail) : localStorage.setItem('candidate_is_personal_details', 'false');

                    if (res.data.data.is_education_detail) {
                        localStorage.setItem('candidate_is_education_details', res.data.data.is_education_detail);
                    } else {
                        localStorage.setItem('candidate_is_education_details', 'false');
                    }

                    if (res.data.data.is_experience_detail) {
                        localStorage.setItem('candidate_is_experience_details', res.data.data.is_experience_detail);
                    } else {
                        localStorage.setItem('candidate_is_experience_details', 'false');
                    }

                    if (res.data.data.is_preference_detail) {
                        localStorage.setItem('candidate_is_preference_details', res.data.data.is_preference_detail);
                    } else {
                        localStorage.setItem('candidate_is_preference_details', 'false');
                    }
                    navigate('/')
                } else {
                    navigate('/login')
                    toast('❌' + res.data.message);
                }
            }).catch((error) => {
                console.log(error);
            })
        } else {
            company_verifyotp(mobile, otp).then((res) => {
                if (res.data.status == "success") {
                    localStorage.setItem('company_id', res.data.data.id);

                    if (res.data.data.is_profile_completed) {
                        localStorage.setItem('company_is_profile_completed', res.data.data.is_profile_completed);
                    } else {
                        localStorage.setItem('company_is_profile_completed', 'false');
                    }
                    navigate('/')
                }else{
                    navigate('/login')
                    toast('❌' + res.data.message);
                }
            }).catch((err) => {
                
            })
        }
    }


    const resendotpbtn = () => {
        candidate_resend_otp(mobile).then((res) => {
            toast('✅ Otp Resend Successfully');
            setcounter(60)
        }).catch((error) => {
            toast('❌ Otp Send Failed and Something Went Wrong');
        })
        setcounter(60)
    }


    return (
        <>
            <Helmet>
                <title>Verify Your Phone Number with OTP | EJOBEE</title>
                <meta name="description" content="Complete OTP verification to secure your eJobee account. Enter the one-time password sent to your phone number to proceed. Join eJobee and access exclusive job opportunities." />
            </Helmet>
            <div className='main-login'>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-7 d-flex align-items-center justify-content-center">
                            <img className='w-100' loading='lazy' src="/assets/images/login-mobile.png" alt="EJOBEE" />
                        </div>
                        <div className="col-lg-5">
                            <div className="login-box d-flex flex-column justify-content-center h-100">
                                <h2>Verify Mobile Number</h2>
                                <p>Enter Otp Sent to <b>+{mobile}</b> for verify Your Number And Start Journey WIth Us</p>
                                <div className="login-form mt-2">
                                    <div className="form-group mb-2">
                                        <OTPInput value={otp} numInputs={4} onChange={setotp} containerStyle='otpcontainer' inputStyle='otpinputclass' renderInput={(props) => <input {...props} />} />
                                        <div className='input-error'>{otperror}</div>
                                    </div>
                                    <span className='d-flex gap-2'>didn't get otp? {counter > 0 ? `Resend in ${counter}s` : <div className='text-underline' style={{ cursor: 'pointer' }} onClick={resendotpbtn}>Send OTP Again</div>} </span>
                                    <div id='recaptcha'></div>
                                    <div onClick={otpverifybtn} className='btn btn-primary mt-3 w-100'>Continue</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Otp