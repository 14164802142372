import React from 'react'
import Navbar from '../company/component/Navbar'
import { Outlet } from 'react-router-dom'
import Sidebar from '../company/component/Sidebar'

const CompanyLayout = () => {
  return (
    <div>
      <Navbar />
      <div>
        <Sidebar />
        <Outlet />
      </div>
    </div>
  )
}

export default CompanyLayout