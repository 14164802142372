import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';

export function Comp_Resm_4(props){
    const [resumeData,setResumeData]=useState({});
    useEffect(()=>{
        if(typeof props.setProfile.candidate_master!=="undefined"){
            setResumeData(setResumeTemplateData(props.setProfile));
            
setTimeout(() => {
    bindEdit();
  }, 1000);
          }
      },[props.setProfile])

    return(<div class="fifthtemplate">
        {typeof resumeData.basic!=="undefined"?
        <div class="container">
          <div class="left_Side">
              <div class="profileText">
                  <div class="imgBx">
                    {typeof resumeData.basic.profile_pic!=="undefined"?
                      <img src={public_url+resumeData.basic.profile_pic} class="photo" />:''
                    }
                    
                  </div>
                  <br/>
                  <h2>{resumeData.basic.first_name} {resumeData.basic.last_name}  <br/><span class="editable" e_type="text" e_key="f" e_id="ft">Web Developer</span> </h2>
              </div>
      
              <div class="contactInfo">
                  <h3 class="title">Details</h3>
                  <ul>
                  <li>
                          <span class="icon"><i class="fa fa-globe" aria-hidden="true"></i></span>
                          <span class="text">DOB:{resumeData.basic["dob"]}</span>
                      </li>
                      <li>
                          <span class="icon"><i class="fa fa-phone" aria-hidden="true"></i></span>
                          <span class="text">{resumeData.contact.mobile}</span>
                      </li>
                      <li>
                          <span class="icon"><i class="fa fa-envelope-o" aria-hidden="true"></i></span>
                          <span class="text">{resumeData.contact.email}</span>
                      </li>
                     
                      {/* <li>
                          <span class="icon"><i class="fa fa-linkedin" aria-hidden="true"></i></span>
                          <span class="text">www.linkedin/me</span>
                      </li> */}
                      <li>
                          <span class="icon"><i class="fa fa-map-marker" aria-hidden="true"></i></span>
                          <span class="text">{resumeData.contact.address}</span>
                      </li>
                  </ul>
              </div>
              <div class="contactInfo education">
                  <h3 class="title">Education</h3>
                  <ul>
                    {resumeData.education.map((item,index)=>{
                        return <li>
                        <h5>{item["duration"]}</h5>
                        <h4>{item["degree"]}</h4>
                        <h4>{item["university"]}</h4>
                    </li>
                    })}
                  </ul>
              </div>
              <div class="contactInfo language">
                  <h3 class="title">Languages</h3>
                  
                    {resumeData.language.map((item,index)=>{
                       return <ul>
                            <li>
                          <span class="text">{item}</span>
                          <span class="percent">
                              <div class="englishw50"></div>
                          </span>
                      </li>
                  </ul>
                    })}
              </div>
          </div>
          <div class="right_Side">
              <div class="about">
                  <h2 class="title2">Profile</h2>
                  <p class="editable" e_type="textarea" e_key="f" e_id="ft">Highly skilled and motivated Web Developer with over [X] years of experience in designing, developing, and maintaining responsive and user-friendly websites. Proficient in front-end technologies such as HTML5, CSS3, JavaScript, and frameworks like React.js and Angular.
                      <br/> <br/>I am passionate about staying current with industry trends and continuously improving my skills to deliver cutting-edge web applications.</p>
              </div>
              <div class="about">
                  <h2 class="title2">Experience</h2>
                  {resumeData.experience.map((item,index)=>{
                    return <div class="box">
                    <div class="year_company">
                        <h5>{item["duration"]}</h5>
                        <h5>{item["company_name"]} </h5>
                    </div>
                    <div class="text">
                        <h4>{item["jobrole_name"]}</h4>
                        <p class="editable" e_type="textarea" e_key="f" e_id="ft">Developed and maintained responsive web applications using React.js and Redux, enhancing user experience and functionality.</p>
                    </div>
                </div>
                  })}
                 
              </div>
              <div class="about skills">
                  <h2 class="title2">Professionals skills</h2>
                  {resumeData.skills.map((item,index)=>{
                    return <div class="box">
                    <h4>{item}</h4>
                    <div class="percent">
                        <div class="htmlws30"></div>
                    </div>
                </div>
                  })}
                  
                 
              </div>
              <div class="about interest">
                  <h2 class="title2">Prefences</h2>
                  <ul>
                    {resumeData.preference.map((item,index)=>{
                        return <>
                        <li><i class="fa fa-book" aria-hidden="true"></i>{item["work"]}</li>
                        <li><i class="fa fa-gamepad" aria-hidden="true"></i>{item["job_time"]}</li>
                        <li><i class="fa fa-cutlery" aria-hidden="true"></i> {item["job_shift"]}</li>
                        <li><i class="fa fa-microphone" aria-hidden="true"></i>{item["city_name"]}</li>
                        </>
                    })}
                      
                     
                  </ul>
              </div>
      
      
          </div>
      </div>
      :''}
      </div>)
}
export default Comp_Resm_4;