import React from 'react'
import { Link } from 'react-router-dom'

const Sidebar = ({ isactive, menuclick }) => {
    return (
        <div className={`web-sidebar ${isactive ? 'active' : ''}`}>
            <div className="backdrop" onClick={menuclick} ></div>
            <div className="side-bar">
                <div className="container">
                    <div className="side-top">
                        <Link to={''} className="logo">
                            <img className='w-100' src="/assets/logo/logo.png" alt="EJOBEE" />
                        </Link>
                    </div>
                    <div className="side-links">
                        <ul>
                            <li>
                                <Link to={''}>Home</Link>
                            </li>
                            <li>
                                <Link to={'/about-us'}>About Us</Link>
                            </li>
                            <li>
                                <Link to={'/contact-us'}>Contact Us</Link>
                            </li>
                            <li>
                                <Link to={'/jobs'}>Jobs</Link>
                            </li>
                            <li>
                                <Link to={'/premium'}>Premium</Link>
                            </li>
                            <li>
                                <Link to={'/blogs'}>Blogs</Link>
                            </li>
                            <li>
                                <Link to={'/faq'}>Faq</Link>
                            </li>
                            <li>
                                <Link to={'/resume_sample'}>Make a Resume</Link>
                            </li>
                            <li>
                                <Link to={'/privacy-policy'}>Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to={'/terms-and-conditions'}>Terms And Conditions</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Sidebar