import $ from 'jquery';
import React,{useEffect} from 'react';
import {RespBkMthdMapp} from '../../functional_lib/common_lib';
import {setFilters} from '../../functional_lib/common_lib';
import ReactSelect from 'react-select';

import './search_filters.css';

export function Candidate_Filters(props){

    
  const changeSelecthandle = (value, action) => {
//alert(JSON.stringify(value));
$("#skills_search").val(JSON.stringify(value));
let hiddenObject=document.getElementById('skills_search');
//alert($("#skills_search").val());
var event = new Event('change');
hiddenObject.dispatchEvent(event);


    // setformdata({
    //   ...formdata,
    //   [action.name]: value
    // })
  }


    useEffect(()=>{
        // alert(JSON.stringify(props.filters.skills));
// filter settings
RespBkMthdMapp(this,"hevent_flt_rightbar",function(data){
//     alert(JSON.stringify(data));
// return;
    props.runSearch(data);
  });
  RespBkMthdMapp(this,"reset_filters",function(data){
    //alert(JSON.stringify(data));
    props.runSearch(data);
  });
  setFilters();
  
  
    },[props.filters])
   
    //alert(JSON.stringify(props.filters.job_types));
    return (
            <aside class="col-lg-3 filter_bar" id="sidebar_fixed">
                <a href="javascript:void(0)" class="open_filters"><div class="layer"></div></a>
                {/* <!-- Mobile menu overlay mask --> */}
                <div class="filter_col">
                <a class="reset_filters" href="javascript:void(0)"><span class="badges"><span class="new">Reset Filters</span></span></a>
                </div>
                <div class="filter_col">
                    <div class="inner_bt"><a href="#" class="open_filters"><i class="fa fa-times" aria-hidden="true"></i></a></div>

                    <div class="filter_type version_2">
                        <h4><a href="#filter_6" data-toggle="collapse" class="opened">City</a></h4>
                        <div class="collapse show" id="filter_6">
                            <div class="input-group">
                                <input class=" hcls_flt_city form-control" type="text" placeholder="Search City" />
                                <button class="hevent_flt_rightbar" type="submit"><i class="fa fa-search-plus" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>

                    
                    <div class="filter_type version_2">
                        <h4><a href="#filter_7" data-toggle="collapse" class="opened">Candiates</a></h4>
                        <div class="collapse show" id="filter_7">
                            
                            <ul>
                            <li>
                                    <label class="container_radio">All Candidates<small></small>
                                        <input type="radio" name="candi_premim_search" className="hevent_flt_rightbar hcls_flt_premium" value="0"  />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_radio">Premium Candidates<small></small>
                                        <input type="radio" name="candi_premim_search" className="hevent_flt_rightbar hcls_flt_premium" value="1"  />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_radio">Non Premium Candidates<small></small>
                                        <input type="radio" name="candi_premim_search" className="hevent_flt_rightbar hcls_flt_premium" value="2" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>    
                            
                        </div>
                    </div>

                    <div class="filter_type version_2">
                        <h4><a href="#filter_7" data-toggle="collapse" class="opened">Skills</a></h4>
                        <div class="collapse show" id="filter_7">
                            
                            <ReactSelect
                onChange={changeSelecthandle}
                // isMulti
                name="skills"
                options={props.filters.skills}
                // defaultValue={defaultSelected}
                className="basic-multi-select"
                classNamePrefix="select" 
                />
                            <input type="hidden" class={"hevent_flt_rightbar hcls_flt_skills"} id="skills_search" name="skills_search" />
                        </div>
                    </div>
{/*
                    <div class="filter_type version_2">
                        <h4><a href="#filter_1" data-toggle="collapse" class="opened">Salary/Month</a></h4>
                        <div class="collapse show" id="filter_1">
                            <ul>
                                <li>
                                    <label class="container_check">₹10,000 — ₹15,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="10000,15000"  />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹15,000 — ₹25,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="15000,25000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹25,000 — ₹45,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="25000,45000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹45,000 — ₹65,000<small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="45000,65000" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">₹65,000<b>+</b>  <small></small>
                                        <input type="checkbox" className="hevent_flt_rightbar hcls_flt_price" value="65000,-11" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                            </ul>
                        </div>
                    </div>
                    */}
                    
                    <div class="filter_type version_2">
                        <h4><a href="#filter_4" data-toggle="collapse" class="open">Job Shift</a></h4>
                        <div class="collapse show" id="filter_4" >
                            {/* <div class="custom-search-input">
                                <input type="text" placeholder="Search" />
                                <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div> */}
                            <ul>
                                {typeof props.filters.job_shifts!=="undefined"?props.filters.job_shifts.map((item,index)=>{
                                    return(<li>
                                        <label  class="container_radio">{item.name} <small></small>
                                            <input  type="radio" class="hevent_flt_rightbar hcls_flt_job_shifts" name="flt_jobshifts" value={item.id} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>)
                                })
                                :''}
                                
                            </ul>
                        </div>
                        {/* <!-- /filter_type --> */}
                    </div>
                    {/* <!-- /filter_type --> */}
                    {/* <div class="filter_type version_2">
                        <h4><a href="#filter_2" data-toggle="collapse" class="opened">Color</a></h4>
                        <div class="collapse show" id="filter_2">
                            <ul>
                                <li>
                                    <select class="form-control hevent_flt_rightbar_nn hcls_flt_color">
                                        <option value="0">Color Options</option>
                                        <option value="RED">RED</option>
                                        <option value="WHITE">WHITE</option>
                                        <option value="YELLOW">YELLOW</option>
                                    </select>
                                </li>
                                
                            </ul>
                        </div>
                    </div> */}
                    {/* filter 5 */}
                    <div class="filter_type version_2">
                        <h4><a href="#filter_5" data-toggle="collapse" class="open">Education</a></h4>
                        <div class="collapse show" id="filter_5" >
                            {/* <div class="custom-search-input">
                                <input type="text" placeholder="Search" />
                                <button type="submit"><i class="fa-solid fa-magnifying-glass"></i></button>
                            </div> */}
                            <ul>
                                {typeof props.filters.education_levels!=="undefined"?props.filters.education_levels.map((item,index)=>{
                                    return(<li>
                                        <label  class="container_radio">{item.name} <small></small>
                                            <input  type="radio" class="hevent_flt_rightbar hcls_flt_edu_level" name="flt_edu_level" value={item.id} />
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>)
                                })
                                :''}
                                
                            </ul>
                        </div>
                        {/* <!-- /filter_type --> */}
                    </div>
                    {/* end of filter 5 */}
                    {/* <!-- /filter_type --> */}
                    <div class="filter_type version_2">
                        <h4><a href="#filter_3" data-toggle="collapse" class="open">Job Categories</a></h4>
                        <div class="collapse show" id="filter_3">
                            <ul>
                            <label class="container_radio">{"All"}<small></small>
                                            <input type="radio" className="hevent_flt_rightbar hcls_flt_job_type" name="hcls_flt_brand" value={0}/>
                                            <span class="checkmark"></span>
                                        </label>
                                {typeof props.filters.job_types!=="undefined"?props.filters.job_types.map((item,index)=>{
                                    
                                        return (<li>
                                        <label class="container_radio">{item.name}<small></small>
                                            <input type="radio" className="hevent_flt_rightbar hcls_flt_job_type" name="hcls_flt_brand" value={item.id}/>
                                            <span class="checkmark"></span>
                                        </label>
                                    </li>)
                                    
                                }):''}
                                {/* <li>
                                    <label class="container_check">Adidas <small>11</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">Nike <small>08</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">Vans <small>05</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li>
                                <li>
                                    <label class="container_check">Puma <small>18</small>
                                        <input type="checkbox" />
                                        <span class="checkmark"></span>
                                    </label>
                                </li> */}
                            </ul>
                        </div>
                    </div>
                    {/* <!-- /filter_type --> */}
                    
                </div>
            </aside>

            // {/* <!--end mobile view filter button --> as of now place below code to applier compoent */}

            // <div class="mobile-filter-button">
            //     <a href="#0" class="btn btn-warning open_filters filter-button">
            //         <i class="fa-sharp fa-solid fa-filter my-float"></i>
            //     </a>
            // </div>
            
            // {/* <!--end mobile view filter button --> */}
//         </div>
//     </div>
// </section>
)
}
export default Candidate_Filters;