import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ReactSelect from 'react-select';
import { alldegree_service, allskills_service, companyjobsbyid_service, education_level_service, postcandidaterequirement_service } from '../../ApiServices';
import { toast } from 'react-toastify';
import {LanguageList} from '../../functional_lib/common_lib';

const CandidateRequirement = (props) => {
  let formStep=props.formStep;
  let prevclick=props.prevclick;
  let nxbtnclick=props.nxbtnclick;
  let setjobid=props.setjobid;
  let jobid=props.jobid;

  const [educationleveloption, seteducationleveloption] = useState([]);
  const [degreeoption, setdegreeoption] = useState([]);
  const [skilloption, setskilloption] = useState([]);

  const [listLanguage,setListLanguage]=useState(LanguageList());


  const [formdata, setformdata] = useState({
    educationlevel: "",
    degree_name: "",
    experiencetype: "",
    experience_year: "",
    englishlevel: "",
    gender: "",
    minage: "",
    maxage: "",
    skills: "",
    jobdescription: "",
  })

  const [errors, seterrors] = useState({});


  useEffect(() => {
    //alert(jobid);
    if (jobid !== null) {
      companyjobsbyid_service(jobid).then((res) => {
        
        if (res.data.jobs[0].candidate_requirement === 1) {
          //alert("i m in set form data "+JSON.stringify(res.data.jobs[0]));
          //alert(res.data.jobs[0].skills);
          setformdata({
            educationlevel: { value: res.data.jobs[0].education_level.id, label: res.data.jobs[0].education_level.name },
            //degree: { value: res.data.jobs[0].degree.id, label: res.data.jobs[0].degree.name },
             degree_name:res.data.jobs[0].degree_name,
             experiencetype: res.data.jobs[0].experience,
             experience_year: res.data.jobs[0].exp_year,
             englishlevel: res.data.jobs[0].english_level,
             gender: res.data.jobs[0].gender,
             minage: res.data.jobs[0].minage,
             maxage: res.data.jobs[0].maxage,
             skills: JSON.parse(res.data.jobs[0].skills),
             jobdescription: res.data.jobs[0].job_description
          })

          //alert(JSON.stringify(formdata));
        }
      }).catch((error) => {
//alert(error);
      })
    }
  }, [props.jobid])

  useEffect(() => {

    if (jobid == null) {
      prevclick();
    }

    // education level
    education_level_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      seteducationleveloption(mappedoptions)
    }).catch((err) => { });


    alldegree_service().then((res) => {
      const mappedoptions = res.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setdegreeoption(mappedoptions)
    }).catch((err) => {
      console.log(err);
    })

    allskills_service().then((res) => {
      const mappedoptions = res.data.data.map((v) => ({
        value: v.id,
        label: v.name
      }))
      setskilloption(mappedoptions);
    }).catch((err) => {

    })

  }, [])

  const validateform = () => {
    const newerrors = {}

    if (!formdata.educationlevel) {
      newerrors.educationlevel = "Please Choose Education Level"
    }

    if (formdata.educationlevel.value > 2) {
      if (!formdata.degree_name) {
        newerrors.degree_name = "Degree Required"
      }
    }

    if (!formdata.experiencetype) {
      newerrors.experiencetype = "Please Choose Experience"
    }

    if (formdata.experiencetype == "experienced") {
      if (!formdata.experience_year) {
        newerrors.experience_year = "Experience is Required"
      }
    }

    if (!formdata.englishlevel) {
      newerrors.englishlevel = "Please Choose English Level"
    }

    if (!formdata.gender) {
      newerrors.gender = "Please Choose Gender"
    }

    if (!formdata.minage) {
      newerrors.minage = "minimum Age is Required"
    }

    if (!formdata.maxage) {
      newerrors.maxage = "Maximum Age is Required"
    }

    if (!formdata.skills) {
      newerrors.skills = "Skills Is Required";
    }

    if (!formdata.jobdescription) {
      newerrors.jobdescription = "Job Description is Required"
    }

    seterrors(newerrors);
    return Object.keys(newerrors).length === 0;
  }

  const HandleChange = (e) => {
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    });
    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }))
  }


  const handleChangeEditor = (editor) => {
    setformdata({
      ...formdata,
      jobdescription: editor
    })

    seterrors((prevErrors) => ({
      ...prevErrors,
      jobdescription: ''
    }))

  };


  const handleSelectChange = (value, action) => {

    setformdata({
      ...formdata,
      [action.name]: value
    })

    seterrors((prevErrors) => ({
      ...prevErrors,
      [action.name]: ''
    }))

  }

  const backbtnclick = () => {
    prevclick()
  }

  const continuebtnclick = () => {
    if (validateform()) {
      postcandidaterequirement_service(jobid, formdata).then((res) => {
        nxbtnclick();
      }).catch((error) => {
        toast('❌' + error.message);
      })
    }
  }


  return (
    <div className="container-fluid">
      <div className="row mt-3">
        <div className="card">
          <h5 className='mt-3 mb-0'>Candidate Requirement</h5>
          <p>Fill What Type of Candidate Required.</p>
          <div className="row mt-3 mb-2">
            <div className="col-lg-12">
              <div class="form-group">
                <label for="first_name" class="label">
                  Minimum Education <span className='text-danger'>*</span>
                </label>
                <ReactSelect value={formdata.educationlevel ? formdata.educationlevel : ''} options={educationleveloption} name='educationlevel' onChange={handleSelectChange} />

                {errors.educationlevel && <span className='text-danger'>{errors.educationlevel}</span>}

              </div>
            </div>
          </div>

          {
            formdata.educationlevel.value > 2 ?
              <div className="row mt-3 mb-3">
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="first_name" class="label">
                      Degree <span className='text-danger'>*</span>
                    </label>
                    <input
                    type="text"
                    name='degree_name'
                    value={formdata.degree_name}
                    class="form-control"
                    placeholder="Degree Name"
                    onChange={HandleChange}
                  />
                    {/* <ReactSelect value={formdata.degree} options={degreeoption} onChange={handleSelectChange} placeholder="Degree" name='degree' /> */}
                    {errors.degree_name && <span className='text-danger'>{errors.degree_name}</span>}
                  </div>
                </div>
              </div> : ""
          }

          <div className="row mt-3 mb-2">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                  Total Experience Required <span className='text-danger'>*</span>
                </label>

                <div className='d-flex gap-2'>
                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="any"
                      checked={formdata.experiencetype == "any"}
                      name="experiencetype"
                      onChange={HandleChange}
                    />
                    Any
                  </label>

                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="experienced"
                      checked={formdata.experiencetype == "experienced"}
                      name="experiencetype"
                      onChange={HandleChange}
                    />
                    Experienced
                  </label>

                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="fresher"
                      checked={formdata.experiencetype == "fresher"}
                      name="experiencetype"
                      onChange={HandleChange}
                    />
                    Fresher
                  </label>
                </div>

                {errors.experiencetype && <span className='text-danger'>{errors.experiencetype}</span>}

              </div>
            </div>
          </div>

          {
            formdata.experiencetype == "experienced" ?
              <div className="row mt-3 mb-2">
                <div className="col-lg-6">
                  <div class="form-group">
                    <label for="first_name" class="label">
                      Minimum Experience <span className='text-danger'>*</span>
                    </label>
                    <div className='d-flex gap-2'>
                      <input value={formdata.experience_year} className='form-control' type="number" placeholder='Number of Years' min="0" name='experience_year' onChange={HandleChange} />
                    </div>
                    {errors.experience_year && <span className='text-danger'>{errors.experience_year}</span>}
                  </div>
                </div>
              </div> :
              ""
          }

          <div className="row mt-3 mb-2">
            <div className="col-lg-12">
              <div class="form-group">

                <label for="first_name" class="label">
                  English Level Required <span className='text-danger'>*</span>
                </label>

                <div className='d-flex gap-2'>
                  {listLanguage.map((item,index)=>{
                    return  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value={item.value}
                      checked={formdata.englishlevel == item.value}
                      name="englishlevel"
                      onChange={HandleChange}
                    />
                    {item.label}
                  </label>
                  })}
                  {/* <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="basic"
                      checked={formdata.englishlevel == "basic"}
                      name="englishlevel"
                      onChange={HandleChange}
                    />
                    Basic
                  </label>
                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      checked={formdata.englishlevel == "intermediate"}
                      value="intermediate"
                      name="englishlevel"
                      onChange={HandleChange}
                    />
                    Intermediate
                  </label>
                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      checked={formdata.englishlevel == "advanced"}
                      value="advanced"
                      name="englishlevel"
                      onChange={HandleChange}
                    />
                    Advanced
                  </label> */}
                </div>
                {errors.englishlevel && <span className='text-danger'>{errors.englishlevel}</span>}
              </div>
            </div>
          </div>
        </div>


        <div className='card mt-3'>
          <h5 className='mt-3 mb-0'>Additional Requirement (Optional)</h5>
          <p>Fill Additional Requirement For Exact Match</p>

          <div className="row mt-3 mb-2">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                  Gender <span className='text-danger'>*</span>
                </label>
                <div className='d-flex gap-2'>
                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="both"
                      name="gender"
                      checked={formdata.gender == "both"}
                      onChange={HandleChange}
                    />
                    Both
                  </label>
                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="male"
                      name="gender"
                      checked={formdata.gender == "male"}
                      onChange={HandleChange}
                    />
                    Male
                  </label>
                  <label className='d-flex align-items-center justify-content-center card p-2 flex-row gap-2'>
                    <input
                      type="radio"
                      value="female"
                      checked={formdata.gender == "female"}
                      name="gender"
                      onChange={HandleChange}
                    />
                    Female
                  </label>
                </div>
                {errors.gender && <span className='text-danger'>{errors.gender}</span>}
              </div>
            </div>
          </div>

          <div className="row mt-3 mb-2">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                  Age <span className='text-danger'>*</span>
                </label>
                <div className='d-flex'>
                  <div className="col-lg-5">
                    <input value={formdata.minage} type="number" min="0" className='form-control' name='minage' placeholder='Minimum Age' onChange={HandleChange} />
                  </div>
                  <div className="col-lg-1">
                    <div style={{ border: '1px solid #ccc', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#ccc', color: 'black' }}>
                      to
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <input value={formdata.maxage} type="number" min="0"  className='form-control' name='maxage' placeholder='maximum age' onChange={HandleChange} />
                  </div>
                </div>
                {errors.minage && <span className='text-danger'>{errors.minage}</span>}
                {
                  errors.minage && errors.maxage ? <span className='text-danger'> and </span> : ""
                }
                {errors.maxage && <span className='text-danger'>{errors.maxage}</span>}
              </div>
            </div>
          </div>
          <div className="row mt-3 mb-3">
            <div className="col-lg-6">
              <div class="form-group">
                <label for="first_name" class="label">
                  Skills <span className='text-danger'>*</span>
                </label>

                <ReactSelect
                  isMulti
                  name="skills"
                  value={formdata.skills}
                  onChange={handleSelectChange}
                  options={skilloption}
                  className="basic-multi-select"
                  classNamePrefix="select" />

              </div>
              {errors.skills && <span className='text-danger'>{errors.skills}</span>}
            </div>
          </div>
        </div>

        <div className='card mt-3'>
          <h5 className='mt-3 mb-0'>Job Description</h5>
          <p>Fill Long And Discriptive Job Description for Candidates</p>
          <div className="row mt-2">
            <div className="col-lg-12">
              <div style={{ height: '250px' }}>
                <ReactQuill value={formdata.jobdescription} onChange={handleChangeEditor} theme="snow" style={{ height: '80%' }} placeholder='Enter the Job Description, Including Responsibilities and skills' />
              </div>
              {errors.jobdescription && <span className='text-danger'>{errors.jobdescription}</span>}
            </div>
          </div>
        </div>
        <div className="row mt-5 mb-5">
          <div className="col-lg-12 text-center">
            <div onClick={backbtnclick} className="btn btn-primary me-2">Back</div>
            <div onClick={continuebtnclick} className="btn btn-primary">Next</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateRequirement