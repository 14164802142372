import React, { useState,useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {getCondidatesView} from '../../ApiServices';

const CompanyAppliedCandidate = () => {
  const navigate = useNavigate();

  const [savedcandidate, setsavedcandidate] = useState([]);
  
  const fetchcandidate = () => {
    let map={ser_company_id:btoa(localStorage.getItem('company_id'))};
    
      getCondidatesView(map,"getCandidateAppliedJobs").then((res) => {
        //alert(res.data);
        const newCandidates = JSON.parse(res.data).applied_jobs;
        setsavedcandidate(newCandidates);
       
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    
  }

  useEffect(()=>{
    fetchcandidate();
  },[])


  return (
    <section style={{ padding: '10px 10px' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column gap-2">
            {/* <h2 style={
              {
                color: 'var(--primary-color)',
              }
            }>You Don't Have Saved Candidate Profile</h2> */}
            {
              savedcandidate.length != 0 ?
                savedcandidate.map((v) => {
                  return (
                    <div className="card p-3">
                      <div className='d-flex gap-2'>
                        <div className="profile-pic d-flex align-items-center">
                          <img src="/assets/images/user_icon.png" style={{ width: '50px', borderRadius: '50%' }} alt="Ejobee" />
                        </div>
                        <div className="profile-details d-flex flex-column" style={{ width: '80%' }}>
                          <div className="profile-name" style={{ fontWeight: 'bold' }}>
                            {v.firstname}{v.lastname}
                          </div>
                          <div className="profile-exp d-flex">
                            <div style={{ fontSize: '14px' }}><b>Applied for Job</b>:{v.jobrole_name}</div>
                          </div>
                          <div className="profile-location d-flex">
                            <div style={{ fontSize: '14px' }}>{v.office_address}</div>
                          </div>
                        </div>
                        <div className="unlock-btn" style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="btn btn-primary">
                            Unlock
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
                : <div style={{ width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <p style={{fontSize:"18px",fontWeight:'bold'}}>Saved Candidate List Empty</p>
                </div>
            }
          </div>
          <div className="col-lg-6 d-flex flex-column" style={{ position: 'relative' }}>
            <img className='w-50' src="/assets/images/saved-profile.png" alt="Ejobee" style={{ position: "fixed" }} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default CompanyAppliedCandidate