import React, { useEffect, useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getCondidatesView } from '../../ApiServices'
import InfiniteScroll from 'react-infinite-scroll-component'
import { public_url } from '../../ApiUrl'
import {Comp_List_Resume} from '../component/Comp_List_Resume';


const Resume_Maker = () => {

   
    useEffect(() => {
        //fetchProfileRecords();
    }, [])

   

    return (
        <>
            <Helmet>
                <title>Resume - Make a resume | EJOBEE</title>
                <meta name="description" content="Explore the eJobee blog for valuable insights, tips, and career advice. Stay updated with the latest trends in the job market and enhance your career growth." />
            </Helmet>
            <section>
                <div className="container">
                    <div className="section-title mb-5">
                        <h4>Make Resumes</h4>
                    </div>
                   
                        <div className="row" style={{ rowGap: '50px' }}>
                            {
                               <Comp_List_Resume lsSample={["1","2","3","4","5","6"]}/>
                            }
                        </div>
                    
                </div>
            </section>
        </>
    )
}

export default Resume_Maker