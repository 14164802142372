import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ReactSelect from 'react-select';
import JobCard from '../component/JobCard';
import Comp_JobCardNew from '../component/Comp_JobCardNew';
import { faqdata, jobsdata, testimonialcarddata } from '../../JsonData';
import { Helmet } from 'react-helmet';
import { alljobrole_service, getallcityservice, latestpublishedjob_service } from '../../ApiServices';
import imgBanner from '../../c_assets/images/banner_1.jpg';

const Home = () => {

    const navigate = useNavigate();

    const [faqactiveIndex, setfaqActiveIndex] = useState(null);

    const [jobcityitems, setjobcityitems] = useState([]);
    const [jobtitleitems, setjobtitleitems] = useState([]);
    const [listExpYear,setListExpYear]=useState([]);

    const [jobtitleSelect, setjobtitleSelect] = useState();
    const [jobcitySelect, setjobcitySelect] = useState();
    const [jobExpYearSelect,setJobExpYearSelect]=useState();

    const [latestjobs, setlatestjobs] = useState([]);


    const [blogs, setblogs] = useState([]);


    useEffect(() => {
        latestpublishedjob_service().then((res) => {
            setlatestjobs(res.data.data);
        }).catch((error) => {

        })
    }, [])

    useEffect(() => {
        getjobroles();
        getjobcities();
        getListExpYear();
    }, [])

   const getListExpYear=()=>{
        let map=[];
        let lbName="";
        for(let k=0;k<=30;k++){
            if(k===0){
                lbName="Fresher (less then 1 year)";
            }else{
                lbName=k===1?k+" Year":k+" Years";
            }
            map.push({id:k,name:lbName});
        }
        setListExpYear(map);
    }

    const getjobroles = () => {
        alljobrole_service().then((data) => {
            setjobtitleitems(data.data.data)
        }).catch((error) => {
        })
    }

    const getjobcities = () => {
        getallcityservice().then((data) => {
            setjobcityitems(data.data)
        }).catch((error) => {
        })
    }



    const faqhandleButtonClick = (index) => {
        setfaqActiveIndex(index === faqactiveIndex ? null : index);
    };



    const onJobtitleSearch = (e) => {
        setjobtitleSelect(e);
    }

    const onJobtitleSelect = (e) => {
        setjobtitleSelect(e.name);
    }

    const onJobcitySelect = (e) => {
        setjobcitySelect(e.name)
    }

    const onJobcitySearch = (e) => {
        setjobcitySelect(e)
    }

    const onExpYearSelect=(e)=>{
        setJobExpYearSelect(e.id);
        //alert(JSON.stringify(e));
    }

    const onSearchbtnClick = (e) => {
        // setsearchParams({ jobtitle: jobtitleSelect, city: jobcitySelect });
        if (jobtitleSelect || jobcitySelect) {
            const searchParams = {};
            if (jobtitleSelect) {
                searchParams.jobtitle = jobtitleSelect;
            }
            if (jobcitySelect) {
                searchParams.city = jobcitySelect;
            }
            if(jobExpYearSelect){
                searchParams.exp_yrs=jobExpYearSelect;
            }
            navigate({
                pathname: '/jobs',
                search: new URLSearchParams(searchParams).toString()
            });
        } else {
            navigate({
                pathname: '/jobs'
            })
        }
    }

    const [activeCard, setActiveCard] = useState(0);

    const handleCardMouseOver = (id) => {
        setActiveCard(id);
    };
    
    const styleSelect = {
        control: base => ({
          ...base,
          border: 0,
          // This line disable the blue border
          boxShadow: 'none'
        })
      };

    return (
        <>
            <Helmet>
                <title>Find Your Dream Job on eJobee | EJOBEE</title>
                <meta name="description"
                    content="eJobee offers a wide range of job opportunities from top companies. Search and apply for your dream job today. Join thousands of successful professionals who found their career growth with eJobee." />
            </Helmet>
            {/* hero section */}
            <section  className='pb-0 hero-section'>
            <img src={imgBanner} />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 d-flex11 div-search">
                            <div className="hero-text d-flex flex-column justify-content-center h-100 gap-2">
                                
                                <Link to={"/resume_sample"} className='mb-0' style={{ width: 'fit-content', textDecoration: 'underline' }}>Make Your Free Resume</Link>
                                <h1 class="center">Find your job now..<br />
                                {/* <span>FIND HERE</span> */}
                                </h1>
                                <div className="search-bar d-flex flex-row gap-2 align-items-center">
                                    <div className="job-title-input">
                                        <ReactSearchAutocomplete items={jobtitleitems} placeholder='Skills,Job Type,City' showIcon={false} onSearch={onJobtitleSearch} onSelect={onJobtitleSelect} styling={{ border: 'none', boxShadow: 'none', zIndex: '999',padding:"26px" }} />
                                    </div>
                                    <div className='divider'></div>
                                    <div className="job-city-input">
                                        {/* <ReactSearchAutocomplete items={jobcityitems} placeholder='Job City' showIcon={false} onSearch={onJobcitySearch} onSelect={onJobcitySelect} styling={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999' }} /> */}
                                        <ReactSelect 
                                        styles={styleSelect}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => btoa(option.id)}
                                        options={listExpYear} placeholder='Select experience' showIcon={false} onChange={onExpYearSelect} style={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999',"border-style":"none" }} />
                                    </div>
                                    <div onClick={onSearchbtnClick} className='search-btn btn btn-primary' style={{ flexBasis: '15%', borderRadius: '24px' }}>
                                        Search
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-4">
                            <img className='w-100' src="/assets/images/hero-section.png" alt="EJOBEE" />
                        </div> */}
                    </div>
                </div>
            </section>

            {/* Latest jobs Section */}
            {
                latestjobs.length != 0 ? <section>
                    <div className="container">
                        <div className="section-title">
                            <h4>Latest Jobs</h4>
                            <Link to={'/jobs'}>View All</Link>
                        </div>
                        <div className="row mt-4 job-row">
                            {
                                latestjobs.slice(0, 6).map((v) => {
                                    return (
                                        // <Comp_JobCardNew/>
                                        <JobCard jobtitle={v.jobrole_name} companyname={v.company.companyname} location={v.city_name} minsalary={v.min_salary} maxsalary={v.max_salary} worktype={v.work_type.name} employementtype={v.job_type.name} slug={v.slug} />
                                    )
                                })
                            }
                        </div>
                    </div>
                </section> : ""
            }

            {/* About Us Section */}
            <section style={{ background: '#f5f5f5' }} className='pb-0'>
                <div className="container">
                    <div className="row reverse">
                        <div className="col-lg-6">
                            <img className='w-100' src="/assets/images/about-section.png" alt="EJOBEE" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>About Us</h4>
                                </div>
                                <p>
                                    At eJobee, we believe in connecting talent with opportunity, fostering professional growth, and empowering individuals to build their dream careers. As a leading job portal with a cutting-edge resume maker, we are dedicated to revolutionizing the way you approach your job search and career development.
                                </p>
                                <Link to={'/about-us'} className='btn btn-secondary'>Know More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* resume Section */}
            <section className='bg-color resume-section'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="resume-box d-flex flex-column align-items-center gap-4">
                                <h2>Don’t just find. Be found. Put your CV in front of great employers</h2>
                                <p>It helps you to increase your chances of finding a suitable job and let recruiters contact you about jobs that are not needed to pay for advertising.</p>
                                <Link to={'/resume_sample'} className='btn btn-primary resume-btn'>Make Your Resume</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* matching Job Section */}
            {
                localStorage.getItem('candidate_googleuid') ?
                    <section>
                        <div className="container">
                            <div className="section-title">
                                <h4>Jobs For You</h4>
                                <Link to={'/jobs'}>View All</Link>
                            </div>
                            <div className="row mt-4 job-row">
                                {
                                    jobsdata.slice(0, 6).map((v) => {
                                        return (
                                            <JobCard jobtitle={v.jobTitle} companyname={v.companyName} companyprofilepic={v.companyProfilePic} location={v.location} minsalary={v.minSalary} maxsalary={v.maxSalary} worktype={"work from Home"} employementtype={v.employmentType} slug={v.slug} />
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section> :
                    ''
            }

            {/* testimonail */}
            <section style={{ background: '#f5f5f5' }} className='testimonial-section'>
                <div className="container">
                    <div className="section-title mb-3">
                        <h4>Testimonial</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div class="cards row">
                                {
                                    testimonialcarddata.map((v, i) => {
                                        return (
                                            <div key={v.id} className={`card col-lg-12 ${activeCard === i ? 'active' : ''}`}
                                                onClick={() => handleCardMouseOver(i)} data-id="content1">
                                                <img src={v.img} alt="" />
                                                <div>
                                                    <h3>{v.name}</h3>
                                                    <p>{v.position}</p>
                                                </div>
                                                <div class="gradient"></div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div class="content">
                                {
                                    testimonialcarddata.map((v, i) => {
                                        return (
                                            <div
                                                key={v.id}
                                                id={v.id}
                                                className={`contentBox ${activeCard === i ? 'active' : ''}`}
                                            >
                                                <div class="text">
                                                    <h2>{v.title}</h2>
                                                    <span>
                                                        {
                                                            [1, 2, 3, 4, 5].map((v) => {
                                                                return (
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                                                                        <path fill-rule="evenodd" d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z" clip-rule="evenodd" />
                                                                    </svg>
                                                                )
                                                            })
                                                        }
                                                    </span>
                                                    <p>{v.description}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* blogs */}
            {
                blogs.length != 0 ?
                    <section>
                        <div className="container">
                            <div className="section-title mb-3">
                                <h4>Blogs</h4>
                            </div>
                            <div className="row" style={{ rowGap: '10px' }}>
                                {
                                    blogs.slice(0, 3).map((b) => {
                                        return (
                                            <Link to={`/blogs/${b.slug}`} className="col-lg-4">
                                                <div class="blog">
                                                    <div class="blog-img">
                                                        <img src={b.image} alt="" />
                                                    </div>
                                                    <div class="blog-content">
                                                        <h2 class="blog-title">{b.title}</h2>
                                                        <p class="blog-desc">{b.shortDescription}</p>
                                                        <div class="blog-details">
                                                            <div class="blog-author-img">
                                                                <img className='rounded-circle' src={b.postedProfilePic} alt="" />
                                                            </div>
                                                            <div class="blog-author-details">
                                                                <h4 class="blog-author-name">{b.postedBy}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </section> : ""
            }

            {/* faq */}
            <section style={{ paddingBottom: '0px', background: '#f5f5f5' }}>
                <div className="container">
                    <div className="section-title mb-3">
                        <h4>FAQ's</h4>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div class='faq-container pb-5'>
                                {
                                    faqdata.map((v, i) => {
                                        return (
                                            <div className={`faq ${i === faqactiveIndex ? 'active' : ''}`}>
                                                <h3 class="faq-title">{v.question}</h3>
                                                <p class="faq-text">{v.answer}</p>
                                                <button onClick={() => faqhandleButtonClick(i)} class='faq-toggle'>
                                                    <svg class="chevron w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 8">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 5.326 5.7a.909.909 0 0 0 1.348 0L13 1" />
                                                    </svg>

                                                    <svg class="close w-3 h-3 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                                    </svg>
                                                </button>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className='w-100' src="/assets/images/faq-section.png" alt="EJOBEE" />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Home