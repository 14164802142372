import React from 'react'
import { Link } from 'react-router-dom'

export function Comp_List_Resume(props){
    let candiLogin=false;
    if (localStorage.getItem('candidate_id')) {
        candiLogin=true;
    }
    return (<>
        {props.lsSample.map((item,index)=>{
            return( <Link to={candiLogin?`/resume_sample/`+item:'/login'} className="col-lg-4 col-sm-6">
                <div className="card p-3 d-flex flex-column gap-1">
                    <div className="info d-flex align-items-center justify-content-center justify-content-between11">
                        <div className="resume d-flex gap-2 align-items-center justify-content-between">
                            <img src={"/assets/resm_thumb/prev"+item+".jpeg"} />
                            
                        </div>
                        
                    </div>
                  
                   
                  
                    
                </div>
    
            </Link>)
        })}
       
   </> )
}

export default Comp_List_Resume