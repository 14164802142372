import React, { useEffect, useState } from 'react'
import { Link,useLocation } from 'react-router-dom'
import { applicationlist_service } from '../../ApiServices';
import { toast } from 'react-toastify';

import {Comp_Company_Payments,Comp_Company_Payments_New} from '../../web/component/Comp_Candidate_Payments';
import {Comp_Receipt_Ejobee} from '../../web/component/comp_receipt_ejobee';
import {getCondidatesView} from '../../ApiServices';


const CompanyPayments = () => {
    const location = useLocation();

    const [loading, setloading] = useState(true);
    const [getPaymentTransactions, setPaymentTransactions] = useState([]);

    const [paymentReceipt,setPaymentReceipt]=useState({});


    useEffect(() => {
        getappliedjobs();
    }, [])

    const _setPaymentReceipt=(transaction_object)=>{
        setPaymentReceipt(transaction_object);
    }

    const getappliedjobs = () => {
        let map={ser_company_id:btoa(localStorage.getItem('company_id'))};
        getCondidatesView(map,'getCandidatePayments').then((res) => {
            //alert(res.data);
            const newCandidates = JSON.parse(res.data).transactions;
            setPaymentTransactions(newCandidates)
        }).catch((error) => {
            toast('❌' + error.message);
        }).finally(() => {
            setloading(false);
        })
    }


    return (
        <>
            {
                loading ? <section>
                    <div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div class="loading-loader"></div>
                    </div>
                </section> : <section className='main-can-profile' style={{ padding: '10px 0px 50px 0px' }}>
                    <div className="container container-sm">
                        {
                            getPaymentTransactions.length != 0 ? <div className='row11' style={{ rowGap: '10px' }}>
                                {
                                    getPaymentTransactions.map((v) => {
                                        return (
                                            <Comp_Company_Payments_New funViewReceipt={_setPaymentReceipt} listTransaction={v} />
                                        )
                                    })
                                }
                            </div> : <div className="row mt-3">
                                <div className="col-lg-6 d-flex justify-content-center">
                                    <img style={{ width: '200px' }} src="/assets/images/appliedjobs.png" />
                                </div>
                                <div className="col-lg-6 d-flex align-items-center text-center">
                                    <h3 style={{ color: 'var(--primary-color)' }}>You didn't make any payments yet</h3>
                                </div>
                            </div>
                        }
                    </div>
                </section >
            }
            <Comp_Receipt_Ejobee getReceipt={paymentReceipt}/>
        </>
    )
}

export default CompanyPayments