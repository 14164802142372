import './App.css';
import { useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from './config/firebase';
import { getAuth } from 'firebase/auth'
import 'react-toastify/dist/ReactToastify.css';

// pages
import Home from './web/pages/Home';
import WebLayout from './layout/WebLayout';
import About from './web/pages/About';
import Contact from './web/pages/Contact';
import Faq from './web/pages/Faq';
import Jobs from './web/pages/Jobs';
import Privacy from './web/pages/Privacy';
import Terms from './web/pages/Terms';
import Login from './web/pages/Login';
import AdminLayout from './layout/AdminLayout';
import CompanyLayout from './layout/CompanyLayout';
import JobDetail from './web/pages/JobDetail';
import Blogs from './web/pages/Blogs';
import BlogDetail from './web/pages/BlogDetail';
import Profile from './web/pages/Profile';
import Pricing from './web/pages/Pricing';
import SavedJobs from './web/pages/SavedJobs';
import ApplyJobs from './web/pages/ApplyJobs';
import PageError from './web/pages/PageError';
import Notification from './web/pages/Notification';
import CandidateProtection from './Auth/CandidateProtection';
import LogoutModel from './web/dialogue/LogoutModel';
import Otp from './web/pages/Otp';
import Register from './web/pages/Register';
import CompanyProfile from './company/pages/CompanyProfile';
import CompanyNotification from './company/pages/CompanyNotification';
import CompanySearchCandidate from './company/pages/CompanySearchCandidate';
import CompanySavedCandidate from './company/pages/CompanySavedCandidate';
import CompanyAppliedCandidate from './company/pages/CompanyAppliedCandidate';
import CompanyPayments from './company/pages/CompanyPayments';
import EducationDetails from './web/pages/EducationDetails';
import ExperienceDetails from './web/pages/ExperienceDetails';
import Preferences from './web/pages/Preferences';
import CompanyRegister from './company/pages/CompanyRegister';
import AdminLogin from './admin/pages/AdminLogin';
import AdminDashboard from './admin/pages/AdminDashboard';
import AdminCandidate from './admin/pages/AdminCandidate';
import AdminCompanies from './admin/pages/AdminCompanies';
import AdminJobs from './admin/pages/AdminJobs';
import AdminContactForm from './admin/pages/AdminContactForm';
import AdminCountry from './admin/pages/AdminCountry';
import AdminState from './admin/pages/AdminState';
import AdminCity from './admin/pages/AdminCity';
import AdminLanguages from './admin/pages/AdminLanguages';
import AdminCurrency from './admin/pages/AdminCurrency';
import AdminEducationLevel from './admin/pages/AdminEducationLevel';
import AdminUniversity from './admin/pages/AdminUniversity';
import AdminDegree from './admin/pages/AdminDegree';
import AdminBlogs from './admin/pages/AdminBlogs';
import AdminChangePassword from './admin/pages/AdminChangePassword';
import AdminJobRole from './admin/pages/AdminJobRole';
import AdminIndustry from './admin/pages/AdminIndustry';
import AdminSkills from './admin/pages/AdminSkills';
import AdminProtection from './Auth/AdminProtection';
import CompanyProtection from './Auth/CompanyProtection';
import { ToastContainer } from 'react-toastify';
import LoginBypass from './Auth/LoginBypass';
import CompanyAllJobs from './company/pages/CompanyAllJobs';
import PostJob from './company/pages/PostJob';
import AdminPerks from './admin/pages/AdminPerks';
import Refund from './web/pages/Refund';
import AdminWorkType from './admin/pages/AdminWorkType';
import AdminJobType from './admin/pages/AdminJobType';
import AdminJobShift from './admin/pages/AdminJobShift';
import AdminJobPlan from './admin/pages/AdminJobPlan';


// dev AshTech
import Resume_Maker from './web/pages/Resume_Maker';
import Resume_Page from './web/pages/Resume_Page';
import CandidatePayments from './web/pages/CandidatePayments';
import Page_Payment_Receipt from './web/pages/page_payment_receipt';
import CompanySearchCandidateNew from './company/pages/CompanySearchCandidateNew';

function App() {

  // firebase config
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const auth = getAuth();
  auth.languageCode = 'en';


  const { pathname } = useLocation();

  const [issidebaractive, setissidebaractive] = useState(false);


  const menubtnclick = () => {
    setissidebaractive(!issidebaractive);
  }

  useLayoutEffect(() => {
    if (issidebaractive) {
      menubtnclick();
    }
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);


  return (
    <>
      <LogoutModel />
      <ToastContainer hideProgressBar />
      
      <Routes>
        {/* candiate and company login signup */}
         <Route path='/login' element={
          <Login/>
        } />
        <Route path='/otp' element={
          <Otp />
        } />
        {/* candiate and company login/signup end */}


        {/* candidate complete profile */}
        <Route path='/complete-profile'>

          <Route index element={
            <Register />} />

          <Route path='education-details' element={
            <EducationDetails />
          } />

          <Route path='experience-details' element={
            <ExperienceDetails />
          } />

          <Route path='preferences' element={
            <Preferences />
          } />

        </Route>
        {/* candidate company profile end */}

        {/* frontend and candidate routes */}
        <Route path='' element={
          <CandidateProtection>
            <WebLayout issidebaractive={issidebaractive} menubtnclick={menubtnclick} />
          </CandidateProtection>
        }>

          <Route index element={<Home />} />

          <Route path='profile' element={
            <CandidateProtection>
              <Profile />
            </CandidateProtection>
          } />

          <Route path='notification' element={
            <CandidateProtection>
              <Notification />
            </CandidateProtection>
          } />
          <Route path='saved-jobs' element={
            <CandidateProtection>
              <SavedJobs />
            </CandidateProtection>
          } />
          <Route path='applied-jobs' element={
            <CandidateProtection>
              <ApplyJobs />
            </CandidateProtection>
          } />
           <Route path='candidate-payments' element={
            <CandidateProtection>
              <CandidatePayments />
            </CandidateProtection>
          } />
           <Route path='payment_receipt' element={
            <CandidateProtection>
              <Page_Payment_Receipt />
            </CandidateProtection>
          } />

{/* <Route path='/login' element={
          <Login/>
        } />
        <Route path='/otp' element={
          <Otp />
        } /> */}
          <Route path='login' element={<Login/>}/>
          <Route path='about-us' element={<About />} />
          <Route path='contact-us' element={<Contact />} />
          <Route path='jobs'>
            <Route index element={<Jobs />} />
            <Route path=':slug' element={<JobDetail />} />
          </Route>
          <Route path='faq' element={<Faq />} />
          <Route path='blogs' element={<Blogs />} />
          <Route path='resume_sample' element={<Resume_Maker/>}/>
          <Route path='resume_sample/:name' element={<Resume_Page/>}/>
          <Route path='blogs/:id/:slug/' element={<BlogDetail />} />
          <Route path='privacy-policy' element={<Privacy />} />
          <Route path='terms-and-conditions' element={<Terms />} />
          <Route path='refund-and-return' element={<Refund />} />
          <Route path='premium' element={<Pricing />} />
          <Route path='*' element={<PageError />} />
        </Route>


        {/* company route */}
        <Route path='/company/complete-profile' element={<CompanyRegister />} />
        <Route path='/company' element={
          <CompanyProtection>
            <CompanyLayout />
          </CompanyProtection>
        }>
          <Route index element={<CompanyAllJobs />} />
          <Route path='Jobs' element={<CompanyAllJobs />} />
          <Route path='profile' element={<CompanyProfile />} />
          <Route path='notification' element={<CompanyNotification />} />
          <Route path='post-job' element={<PostJob />} />
          <Route path='post-job/:jobid' element={<PostJob />} />
          <Route path='search-candidate' element={<CompanySearchCandidateNew />} />
          <Route path='saved-candidate' element={<CompanySavedCandidate />} />
          <Route path='applied-candidate' element={<CompanyAppliedCandidate />} />
          <Route path='company-payments' element={<CompanyPayments />} />
          <Route path='payment_receipt' element={<Page_Payment_Receipt />} />

        </Route>


        {/* admin route */}
        <Route path='admin/login' element={
          <LoginBypass path={'/admin'}>
            <AdminLogin />
          </LoginBypass>
        } />
        <Route path='/admin' element={
          <AdminProtection>
            <AdminLayout />
          </AdminProtection>
        }>
          <Route index element={<AdminDashboard />} />
          <Route path='dashboard' element={<AdminDashboard />} />
          {/* candidate */}
          <Route path='candidate' element={<AdminCandidate />} />
          {/* company */}
          <Route path='companies' element={<AdminCompanies />} />

          {/* jobs */}
          <Route path='jobs' element={<AdminJobs />} />
          <Route path='contact-forms' element={<AdminContactForm />} />

          {/* blogs */}
          <Route path='blogs'>
            <Route index element={<AdminBlogs />} />
          </Route>

          {/* global */}
          {/* country */}
          <Route path='country' element={<AdminCountry />} />
          {/* state */}
          <Route path='state' element={<AdminState />} />
          {/* city */}
          <Route path='city' element={<AdminCity />} />
          {/* language */}
          <Route path='language' element={<AdminLanguages />} />
          {/* currency */}
          <Route path='currency' element={<AdminCurrency />} />
          {/* education level */}
          <Route path='education-level' element={<AdminEducationLevel />} />
          {/* universities */}
          <Route path='university' element={<AdminUniversity />} />
          {/* degree */}
          <Route path='Degree' element={<AdminDegree />} />
          {/* job role */}
          <Route path='job-role' element={<AdminJobRole />} />

          {/* industry */}
          <Route path='industry' element={<AdminIndustry />} />

          {/* skills */}
          <Route path='skills' element={<AdminSkills />} />

          {/* perks */}
          <Route path='perks' element={<AdminPerks />} />

          {/* work type */}
          <Route path='work-type' element={<AdminWorkType />} />

          {/* job type */}
          <Route path='job-type' element={<AdminJobType />} />

          {/* job shift */}
          <Route path='job-shift' element={<AdminJobShift />} />


          {/* job-plan */}
          <Route path='job-plan' element={<AdminJobPlan/>}/>

          <Route path='change-password' element={<AdminChangePassword />} />

        </Route>
      </Routes>
    </>
  );
}

export default App;
