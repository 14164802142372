import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'


const About = () => {
    return (
        <>
            <Helmet>
                <title>
                    About eJobee | EJOBEE
                </title>
                <meta name="description" content="Discover the story behind eJobee - the leading job platform dedicated to helping professionals find their ideal career paths. Learn how eJobee connects job seekers with top employers and fosters career growth." />
            </Helmet>
            <section style={{ background: '#f5f5f5' }} className='pb-0'>
                <div className="container">
                    <div className="row reverse">
                        <div className="col-lg-6">
                            <img className='w-100' src="/assets/images/about-section.png" alt="EJOBEE" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>About Us</h4>
                                </div>
                                <p>
                                    At eJobee, we believe in connecting talent with opportunity, fostering professional growth, and empowering individuals to build their dream careers. As a leading job portal with a cutting-edge resume maker, we are dedicated to revolutionizing the way you approach your job search and career development.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='pb-0'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>Our Vision</h4>
                                </div>
                                <p>
                                    Empowering individuals to reach their full potential by providing a seamless platform that bridges the gap between talented professionals and employers seeking exceptional candidates.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img className='w-100' src="/assets/images/our-vision.png" alt="EJOBEE" />
                        </div>
                    </div>
                </div>
            </section>

            <section style={{ background: '#f5f5f5' }}>
                <div className="container">
                    <div className="row reverse">
                        <div className="col-lg-6">
                            <img className='w-100' src="/assets/images/our-mission.png" alt="EJOBEE" />
                        </div>
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>Our Mission</h4>
                                </div>
                                <p>
                                    At eJobee, our mission is to empower individuals to discover, pursue, and thrive in their dream careers. We are committed to providing a dynamic and inclusive job portal that connects talent with opportunities, fosters professional growth, and redefines the way individuals navigate their career paths.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="about-text d-flex flex-column justify-content-center h-100 gap-3 align-items-start">
                                <div className="section-title">
                                    <h4>Our Commitment</h4>
                                </div>
                                <p>
                                    At eJobee, we are committed to your success. We strive to provide a platform that not only connects you with the right job opportunities but also empowers you to present yourself in the best light through our resume maker. Your career journey is our priority, and we're here to support you every step of the way.
                                </p>
                                <p>
                                    Join eJobee today and embark on a journey towards a fulfilling and rewarding career. Your dream job awaits!
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <img loading='lazy' className='w-100' src="/assets/images/our-comit.png" alt="EJOBEE" />
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default About