import React from 'react'
import { Helmet } from 'react-helmet'

const Privacy = () => {
  return (
    <>
    <Helmet>
      <title>
      Privacy Policy - Your Trust Matters at ejobee
      </title>
    </Helmet>
      <section>
        <div className="container">
          <div className="section-title mb-3">
            <h4>Privacy Policy</h4>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h4>Overview</h4>
              <p>Welcome to eJobee, the Job Platform with Resume Builder ("we," "us," or "our"). This Privacy Policy outlines how we collect, use, disclose, and safeguard your personal information when you use our services, including the job platform and resume builder (collectively, the "Services").</p>

              <h4>Information We Collect</h4>
              <p>
                - Personal Information: When you register an account, use our resume builder, or interact with our platform, we may collect personal information such as your name, contact details, and professional background.
                <br />
                - Resume Information: Information provided or generated through the use of our Resume Builder, including employment history, skills, and education.
                <br />
                - Usage Data: Information about your interactions with our platform, such as pages visited, searches performed, and job applications.
              </p>

              <h4>How We Use Your Information</h4>
              <p>
                - To provide and improve our Services.
                <br />
                - To match you with relevant job opportunities based on your resume and preferences.
                <br />
                - To communicate with you about your account, job applications, and platform updates.
                <br />
                - To enhance and personalize your user experience.
              </p>

              <h4>How We Share Your Information</h4>
              <p>
                - With employers and recruiters when you apply for jobs.
                <br />
                - With service providers assisting us in delivering the Services.
                <br />
                - In response to legal requests or to protect our rights.
              </p>

              <h4>Your Choices</h4>
              <p>
                - You can control the information you provide through your account settings.
                <br />
                - You may choose not to share certain information, but this may limit your access to some features.
              </p>

              <h4>Security</h4>
              <p>
                - We employ industry-standard security measures to protect your information.
                <br />
                - However, no method of transmission over the internet or electronic storage is completely secure.
              </p>

              <h4>Cookies and Similar Technologies:</h4>
              <p>We use cookies and similar technologies to enhance your experience and collect data for analytics.</p>

              <h4>Children's Privacy</h4>
              <p>Our Services are not directed to individuals under the age of 18.</p>

              <h4>Changes to This Privacy Policy</h4>
              <p>We may update this Privacy Policy periodically, and any changes will be posted on this page.</p>

              <h4>Contact Us</h4>
              <p>If you have questions about this Privacy Policy, please contact us at <a style={{ color: 'blue' }} href="#" target='_blank'>yourejobee@gmail.com</a>.</p>

              <p>Thank you for using eJobee!</p>

              <p>Last Updated: 24 Jan 2024</p>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Privacy