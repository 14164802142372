import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom'
import { candidate_send_otp_service, company_sendotp } from '../../ApiServices'
import { toast } from 'react-toastify'

const Login = () => {
//alert("i m in login");
    const navigate = useNavigate();

    const [usertype, setusertype] = useState(1);
    const [mobile, setmobile] = useState('');
    const [mobileerror, setmobileerror] = useState('');

    useEffect(() => {
        if (localStorage.getItem('candidate_id') || localStorage.getItem('company_id')) {
            navigate('/');
        }
    }, [])


    const onloginbtnclick = () => {
        if (mobile === "") {
            setmobileerror('Mobile Number is Required');
        } else {
            setmobileerror('');
            handlesendotp();
        }
    }

    const changeusertype = (type) => {
        //alert(type);
        setusertype(type)
    }


    const onchangemobile = (n, v) => {
        setmobile(n);
    }

    const handlesendotp = async () => {
        if (usertype == 1) {
            candidate_send_otp_service(mobile).then((res) => {
                toast('✅ Otp Send Successfully');
                navigate(`/otp?type=candidate&&mobile=${mobile}`)
            }).catch((err) => {
                toast('❌ Otp Send Failed');
            })
        } else {
            company_sendotp(mobile).then((res) => {
                toast('✅ Otp Send Successfully');
                navigate(`/otp?type=company&&mobile=${mobile}`)
            }).catch((err) => {
                toast('❌ Otp Send Failed');
            })
        }
    }


    return (
        <>
            <Helmet>
                <title>Login to EJOBEE - Access Your Account</title>
                <meta name="description" content="Login to your eJobee account to access personalized job recommendations, saved searches, and application history. Take the next step in your career journey with eJobee." />
            </Helmet>
            <div className='main-login'>
                <div className="container h-100">
                    <div className="row h-100">
                        <div className="col-lg-7">
                            <img className='w-100' loading='lazy' src="/assets/images/login-mobile.png" alt="EJOBEE" />
                        </div>
                        <div className="col-lg-5">
                            <div className="login-box d-flex flex-column justify-content-center h-100" style={{ rowGap: '10px' }}>
                                <h2>Login/Register</h2>
                                <p> Log in now for a personalized journey tailored just for you.</p>
                                <div className="d-flex gap-4">
                                    <div onClick={()=>changeusertype(1)} className={`card login-switch ${usertype == 1 ? 'active' : ""}`}>
                                        Candidate
                                    </div>
                                    <div onClick={()=>changeusertype(2)} className={`card login-switch ${usertype == 2 ? 'active' : ""}`}>
                                        Company
                                    </div>
                                </div>
                                <div className="login-form mt-2">
                                    <div className="form-group">
                                        <label for="email" class="label">
                                            Mobile Number <span className='required' >*</span>
                                        </label>
                                        <PhoneInput country={'in'} specialLabel='' inputClass='form-control phoneinput' placeholder='+91 8093038948' containerStyle={{ width: '100%' }} inputStyle={{ width: '100%' }} onChange={onchangemobile} />
                                        <div className='input-error'>{mobileerror}</div>
                                        <div id="recaptcha"></div>
                                    </div>
                                    <div onClick={onloginbtnclick} className='btn btn-primary mt-3 w-100'>Login/SignUp</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login