import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const Sidebar = () => {

  const location = useLocation();
  const pathname = location.pathname;

  return (
    <>
      {pathname === '/company/profile' || pathname === '/company/profile/' || pathname === '/company/post-job' || pathname === '/company/post-job/' || pathname === '/company/notification' || pathname === '/company/notification/' ? '' :

        <div className="container">
          <div className="row tab-bar scroll-hide" style={{ flexWrap: 'nowrap', overflowX: 'scroll', padding: '10px 0px' }}>
            <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
              <Link to={'jobs'} className={pathname === '/company/jobs' ? 'card p-tab active' : 'card p-tab'}>
                Jobs
              </Link>
            </div>
            <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
              <Link to={'search-candidate'} className={pathname === '/company/search-candidate' ? 'card p-tab active' : 'card p-tab'}>
                Search Candidate
              </Link>
            </div>
            <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
              <Link to={'saved-candidate'} className={pathname === '/company/saved-candidate' ? 'card p-tab active' : 'card p-tab'}>
                Saved Candidates
              </Link>
            </div>
            <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
              <Link to={'applied-candidate'} className={pathname === '/company/applied-candidate' ? 'card p-tab active' : 'card p-tab'}>
               Applied Candidates
              </Link>
            </div>
            <div className="col-lg-2 d-flex" style={{ width: 'fit-content' }}>
              <Link to={'company-payments'} className={pathname === '/company/company-payments' ? 'card p-tab active' : 'card p-tab'}>
              Payment History
              </Link>
            </div>
          </div>
        </div>

        // <div className='container mt-5 company-tabs'>
        //   <ul className='d-flex gap-3'>
        //     <li className={pathname === '/company/jobs' ? 'active' : ''}>
        //       <Link to={'jobs'}>Jobs</Link>
        //     </li>
        //     <li className={pathname === '/company/applications' ? 'active' : ''}>
        //       <Link to={'applications'}>Applications</Link>
        //     </li>
        //     <li className={pathname === '/company/search-candidate' ? 'active' : ''}>
        //       <Link to={'search-candidate'}>Search Candidate</Link>
        //     </li>
        //     <li className={pathname === '/company/saved-candidate' ? 'active' : ''}>
        //       <Link to={'saved-candidate'}>Saved Candidate</Link>
        //     </li>
        //   </ul>
        // </div>
      }
    </>
  )
}

export default Sidebar