import $ from 'jquery';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import ReactSelect from 'react-select';
import JobCard from '../component/JobCard';
import Comp_JobCard from '../component/Comp_JobCard';
import Search_Filters from '../component/Search_Filters';
import { Helmet } from 'react-helmet';
import { alljobrole_service, alljobs_service, getallcityservice } from '../../ApiServices';
import {getCondidatesView} from '../../ApiServices';


const Jobs = () => {

  const useQuery=()=> {
    return new URLSearchParams(useLocation().search);
  }
  //alert("i m in jobs");

  const navigate = useNavigate();

  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [listExpYear,setListExpYear]=useState([]);
  const [jobtitleSelect, setjobtitleSelect] = useState(null);
  const [jobcitySelect, setjobcitySelect] = useState(null);

  const [jobcityitems, setjobcityitems] = useState([]);
  const [jobtitleitems, setjobtitleitems] = useState([]);
  const [jobExpYearSelect,setJobExpYearSelect]=useState(null);

  const [alljobs, setalljobs] = useState([]);

  const [isNoData,setNoData]=useState(false);

  const [searchFilters,setSearchFilters]=useState([]);

  const [formdata, setformdata] = useState({
    jobtitle:"",
    jobcity:""
  });

  const HandleChange=(e)=>{
    const { name, value } = e.target;
    setformdata({
      ...formdata,
      [name]: value
    });

    if(e.target.name==="jobtitle"){
      setjobtitleSelect(e.target.value);
    }
    if(e.target.name==="jobcity"){
      setjobcitySelect(e.target.value);
    }
   
  }

  useEffect(() => {
    getjobroles();
    getjobcities();
    getSearchMasters();
    setsearchinput();
    getListExpYear();
    
    
  }, [])

  useEffect(() => {
    //alert(location.search);
    setsearchinput();
    getalljobs();
    //alert("i m in use effect of search");
    
  }, [location.search])

  const getListExpYear=()=>{
    let map=[];
    let lbName="";
    for(let k=0;k<=30;k++){
        if(k===0){
            lbName="Fresher (less then 1 year)";
        }else{
            lbName=k===1?k+" Year":k+" Years";
        }
        map.push({id:k,name:lbName});
    }
    setListExpYear(map);
}

  const getSearchMasters=()=>{
    let map={};
    map["education_levels"]="1";
    map["job_types"]="1";
    map["job_shifts"]="1";

    getCondidatesView(map,"getMasters").then((res)=>{
      //alert(res.data);
      let lsData=JSON.parse(res.data);
      //alert(searchFilters.job_types);
      //alert(JSON.stringify(lsData.education_levels));
      setSearchFilters(lsData);

    })
  }


  const getalljobs = (jobSearches={}) => {
    // const query = useQuery();
    // let map={job_status:"published"}
    // let jobTitle = query.get('jobtitle');
    // let jobCity=query.get('city');
    setLoading(true);
    let map={job_status:"published"};
    //alert(jobtitleSelect);
    if(jobtitleSelect!==null && jobtitleSelect.trim()!==""){
      map["job_title"]=jobtitleSelect;
    }
    if(formdata.jobcity!==null && formdata.jobcity.trim()!==""){
      map["job_city"]=formdata.jobcity;
    }
    if(jobExpYearSelect!==null && jobExpYearSelect!==""){
      //alert(jobExpYearSelect);
      map["job_exp_year"]=jobExpYearSelect;
    }
    //alert(JSON.stringify(jobSearches));
    map["filters"]=jobSearches;
    //alert(JSON.stringify(map));
    setNoData(false);
    getCondidatesView(map,"getAllJobsList").then((res) => {
      
        //alert(JSON.stringify(res.data));
        const newjobs = JSON.parse(res.data).all_jobs;
        setalljobs(newjobs);
        //alert(newjobs.length);
        if(newjobs.length===0){
          //$("#resp_msg").html("No Results..");
          setNoData(true);
        }
        setLoading(false);
      
    }).catch((error) => {
      alert(error);
      setLoading(false);

    })

    // alljobs_service().then((res) => {
    //   if (res.data.success == true) {
    //     //alert(JSON.stringify(res.data));
       
    //     setalljobs(res.data.data);
    //   }
    // }).catch((error) => {
    //   alert(error);

    // })
  }

  const onExpYearSelect=(e)=>{
    setJobExpYearSelect(e.id);
    //alert(JSON.stringify(e));
}

  const setsearchinput = () => {
    const jobtitleparam = new URLSearchParams(location.search).get('jobtitle');
    const cityparam = new URLSearchParams(location.search).get('city');
    const tmpExpYear=new URLSearchParams(location.search).get('exp_yrs');
    if (jobtitleparam) {
      formdata.jobtitle=jobtitleparam;
      setjobtitleSelect(jobtitleparam)
    }
    if(tmpExpYear){
      setJobExpYearSelect(tmpExpYear);
    }

    if (cityparam) {
      //alert("i m in cituparam");
      formdata.jobcity=cityparam;
      setjobcitySelect(cityparam)
    }
  }


  const getjobroles = () => {
    alljobrole_service().then((data) => {
      //alert(JSON.stringify(data.data));
      // const formattedItems = data.data.data.map((item, index) => ({
      //   id: item.id, // Ensure an id exists, use index as fallback
      //   name: item.name // Ensure a name exists, adjust if your API uses custom keys
      // }));
      // alert(JSON.stringify(formattedItems));
      setjobtitleitems(data.data.data);
    }).catch((error) => {

    })
  }

  const getjobcities = () => {
    getallcityservice().then((data) => {
      //alert(JSON.stringify(data.data));
      setjobcityitems(data.data)
    }).catch((error) => {

    })
  }



  const onJobtitleSearch = (e) => {
    // alert(e);
     setjobtitleSelect(e);
  }

  const onJobtitleSelect = (e) => {
    //alert(e.id);
    setjobtitleSelect(e);
    //setjobtitleSelect({id:e.id,name:e.name});
  }

  const onJobcitySelect = (e) => {
    //alert(JSON.stringify(e));
    setjobcitySelect(e)
  }

  const onJobcitySearch = (e) => {
    setjobcitySelect(e)
  }


  const onSearchbtnClick = () => {
    //alert("i m in sclick");
    if (jobtitleSelect || jobcitySelect || jobExpYearSelect) {
      //alert("i m in click");
      const searchParams = {};
      if (jobtitleSelect) {
        //alert(JSON.stringify(jobtitleSelect));
        searchParams.jobtitle = jobtitleSelect;
      }else{
        //searchParams.jobtitle='';
      }
      if (jobcitySelect) {
        searchParams.city = jobcitySelect;
      }else{
        //searchParams.city='';
      }
      if(jobExpYearSelect){
        searchParams.exp_yrs=jobExpYearSelect;
      }else{
        //searchParams.exp_yrs='';
      }
      navigate({
        pathname: '/jobs',
        search: new URLSearchParams(searchParams).toString()
      });
    } else {
      navigate({
        pathname: '/jobs'
      })
    }
  }

  
  const formatResult = (item) => {
    return (
      <>
        
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  const styleSelect = {
    control: base => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: 'none'
    })
  };

  return (
    <>
      <Helmet>
        <title>
          {jobtitleSelect || jobcitySelect ? `${jobtitleSelect || ''} Jobs ${jobcitySelect ? `in ${jobcitySelect}` : ''} | eJobee` : 'Explore Job Opportunities | EJOBEE'}
        </title>
        <meta name="description" content={`Explore ${jobtitleSelect || 'all'} job openings ${jobcitySelect ? `in ${jobcitySelect}` : ''} on eJobee.`} />
      </Helmet>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="search-bar d-flex flex-row gap-2 align-items-center">
                <div className="job-title-input">
                  <input type="text"
                    type="text"
                    name='jobtitle'
                    value={formdata.jobtitle}
                    class="ser_seachjob form-control11"
                    placeholder="Search for.."
                    onChange={HandleChange}
                    defaultValue={formdata.jobtitle}
                    style={{ width:"100%",outline:'none',border: 'none', boxShadow: 'none', zIndex: '999', }}
                 />
                  {/* <ReactSearchAutocomplete inputSearchString1212={jobtitleSelect} items={jobtitleitems} placeholder='Job Tittle' showIcon={false} onSearch={onJobtitleSearch} onSelect={onJobtitleSelect} styling={{ border: 'none', boxShadow: 'none', zIndex: '999', }} /> */}
                </div>
                <div className='divider'></div>
                <div className="job-city-input">
                <ReactSelect 
                                        styles={styleSelect}
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) => btoa(option.id)}
                                        options={listExpYear} placeholder='Select experience' showIcon={false} onChange={onExpYearSelect} style={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999',"border-style":"none" }} />
                {/* <input type="text"
                    type="text"
                    name='jobcity'
                    value={formdata.jobcity}
                    class="ser_seachjob form-control11"
                    placeholder="City.."
                    onChange={HandleChange}
                    defaultValue={formdata.jobcity}
                    style={{ width:"100%",outline:'none',border: 'none', boxShadow: 'none', zIndex: '999', }}
                 /> */}
                  {/*<ReactSearchAutocomplete inputSearchString12121s={jobcitySelect} items={jobcityitems} placeholder='Job City' showIcon={false} onSearch={onJobcitySearch} onSelect={onJobcitySelect} styling={{ borderRadius: '0', border: 'none', boxShadow: 'none', zIndex: '999' }} />*/}

                </div>
                <button onClick={()=>onSearchbtnClick()} className='search-btn btn btn-primary' style={{ flexBasis: '15%', borderRadius: '24px' }}>
                  Search
                </button>
              </div>
            </div>
          </div>
          <div className="row mt-5 job-row">
          <div class="mobile-filter-button">
    <a href="#0" class="btn1 btn-warning open_filters filter-button">
        <i class="ion-ios-color-filter-outline12 ti-filter12 fa fa-filter white my-float">Filter</i>
</a>
   </div>
          {typeof searchFilters?.job_types!=="undefined"?<Search_Filters runSearch={getalljobs} filters={searchFilters}/>:''
              }
           
                <div class="col-md-9">
                  <div class="row">
                
              
                {loading ===true ?
                <section>
                <div style={{ height: '50vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {loading===true?<div class="loading-loader"></div>:''}
                </div></section>:<div id="resp_msg">{alljobs.length===0?"No jobs found..":''}</div>}
               
                {alljobs.map((v) => {
                
                return (
                  <Comp_JobCard  savedJobs={v} />
                  // <JobCard jobtitle={v.jobrole_name} companyname={v.companyname} location={v.office_address} minsalary={v.min_salary} maxsalary={v.max_salary} worktype={v.work_types} employementtype={v.jobtype_name} slug={v.slug} />
                  // <JobCard jobtitle={v.job_role.name} companyname={v.company.companyname} location={v.office_address} minsalary={v.min_salary} maxsalary={v.max_salary} worktype={v.work_type.name} employementtype={v.job_type.name} slug={v.slug} />
                )
              })
            }
            

            </div>
            </div>
            
          </div>
        </div>
      </section>

    </>
  )
}

export default Jobs