import React, { forwardRef, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import { alljobrole_service, complete_experience_service } from '../../ApiServices'
import {ExperienceYearList} from '../../functional_lib/common_lib';
const ExperienceDetails = () => {

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const id = new URLSearchParams(location.search).get('id');

        if (localStorage.getItem('candidate_id') == null) {
            navigate('/login');
        }

        if (id == '' || id == null) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_is_experience_details') == 'true') {
            navigate('/')
        }

    }, []);


    const [formData, setformData] = useState({
        is_experience: "",
        jobrole_name: "",
        total_experience: "",
        startDate: "",
        companyname: "",
        endDate: "",
        is_working: false
    })

    const [errors, seterrors] = useState({});

    const [jobroleoption, setjobroleoption] = useState([])


    useEffect(() => {
        getjobroles();
    }, [])



    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button onClick={onClick} ref={ref}>
            {value}
        </button>
    ));


    const getjobroles = () => {
        alljobrole_service().then((data) => {
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setjobroleoption(mappedoptions)
        }).catch((error) => { })
    }

    const totaloption = ExperienceYearList();


    const handleChange = (e) => {
        const { name, value } = e.target;
        setformData({
            ...formData,
            [name]: value
        })
        seterrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    }

    const HandleSelectChange = (value, action) => {
        setformData({
            ...formData,
            [action.name]: value
        })
        seterrors((prevErrors) => ({
            ...prevErrors,
            [action.name]: '',
        }));
    }

    const onisworkingclick = () => {
        setformData({
            ...formData,
            is_working: !formData.is_working
        })
    }


    const validateform = () => {
        const newErrors = {};

        if (!formData.is_experience) {
            newErrors.is_experience = "Please Choose A Option"
        } else {
            if (formData.is_experience == "yes") {
                if (!formData.total_experience) {
                    newErrors.total_experience = "Please Select Total Experience"
                }

                if (!formData.companyname) {
                    newErrors.companyname = "Company Name is Required"
                }

                if (!formData.jobrole_name) {
                    newErrors.jobrole_name = "Jobrole Required"
                }

                if (!formData.is_working) {
                    if (!formData.startDate || !formData.endDate) {
                        newErrors.date = "please Select Start And End Date"
                    }
                } else {
                    if (!formData.startDate) {
                        newErrors.date = "please Select Start Date"
                    }
                }
            }

        }

        seterrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    }


    const continuebtnlick = () => {
        if (validateform()) {
            complete_experience_service(formData).then((data) => {
                localStorage.setItem('candidate_is_experience_details', 'true');
                navigate('/')
            }).catch((error) => { })
        }
        // navigate('/create-account/preferences')
    }



    return (
        <div className='main-login'>
            <div className="container h-100">
                <div className="row h-100">
                    <div className="col-lg-7 h-100">
                        <img className='w-100' src="/assets/images/personal-detail.png" alt="EJOBEE" />
                    </div>
                    <div className="col-lg-5">
                        <div className="login-box d-flex flex-column justify-content-center h-100">
                            <h2>Experience Details</h2>
                            <p>Fill Experience Details For FInd Better Opportuninities for you</p>

                            <div className="login-form mt-2">
                                <div className="form-group">

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label htmlFor="">Do You Have Any Experience?</label>
                                            <div className="row mt-2">
                                                <div className="col-lg-6 d-flex gap-4">
                                                    <label className='d-flex gap-1 align-items-center justify-content-center'>
                                                        <input
                                                            type="checkbox"
                                                            value="yes"
                                                            name="is_experience"
                                                            checked={formData.is_experience === 'yes'}
                                                            onChange={handleChange}
                                                        />
                                                        Yes
                                                    </label>
                                                    <label className='d-flex gap-1 align-items-center justify-content-center'>
                                                        <input
                                                            type="checkbox"
                                                            value="no"
                                                            name="is_experience"
                                                            checked={formData.is_experience === 'no'}
                                                            onChange={handleChange}
                                                        />
                                                        No
                                                    </label>
                                                </div>
                                                {errors.is_experience && <span className="text-danger">{errors.is_experience}</span>}
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        formData.is_experience == "yes" ?
                                            <div className="row mt-2">
                                                <div className="col-lg-12">
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        placeholder="Total Year of Experience"
                                                        isSearchable={true}
                                                        name="total_experience"
                                                        value={formData.total_experience}
                                                        onChange={HandleSelectChange}
                                                        options={totaloption}
                                                    />
                                                    {errors.total_experience && <span className="text-danger">{errors.total_experience}</span>}
                                                </div>
                                                <div className="col-lg-12 mt-2">
                                                    <input type="text" placeholder='Company Name' className='form-control' value={formData.companyname} onChange={handleChange} name='companyname' />
                                                    {errors.companyname && <span className="text-danger">{errors.companyname}</span>}
                                                </div>
                                                <div className="col-lg-12 mt-2">
                                                <input
                    type="text"
                    name='jobrole_name'
                    value={formData.jobrole_name}
                    class="form-control"
                    placeholder="Job Role"
                    onChange={handleChange}
                  />

                                                    {/* <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        placeholder="Job Role"
                                                        isSearchable={true}
                                                        name="jobrole"
                                                        value={formData.jobrole}
                                                        onChange={HandleSelectChange}
                                                        options={jobroleoption}
                                                    /> */}
                                                    {errors.jobrole_name && <span className="text-danger">{errors.jobrole_name}</span>}
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-6 mt-3 d-flex flex-column">
                                                            <label className='me-2'>Start Date</label>
                                                            <ReactDatePicker selected={
                                                                !formData.startDate ? new Date() : formData.startDate
                                                            }
                                                                showMonthYearPicker
                                                                className='form-control' dateFormat="MMMM yyyy"
                                                                name='startDate'
                                                                placeholderText='Start Year' onChange={(date) => {
                                                                    setformData({
                                                                        ...formData,
                                                                        startDate: date
                                                                    })
                                                                }}
                                                                maxDate={new Date()} customInput={<ExampleCustomInput />} />
                                                        </div>
                                                        {
                                                            formData.is_working ? '' : <div className="col-lg-6 mt-3 d-flex flex-column">
                                                                <label className='me-2'>end Date</label>
                                                                <ReactDatePicker selected={
                                                                    !formData.endDate ? new Date() : formData.endDate
                                                                }
                                                                    showMonthYearPicker
                                                                    className='form-control' dateFormat="MMMM yyyy"
                                                                    name='endDate'
                                                                    placeholderText='Start Year' onChange={(date) => {
                                                                        setformData({
                                                                            ...formData,
                                                                            endDate: date
                                                                        })
                                                                    }}
                                                                    maxDate={new Date()} customInput={<ExampleCustomInput />} />
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        errors.date && <span className='text-danger' >{errors.date}</span>
                                                    }
                                                </div>

                                                <div className="col-lg-12 mt-2">
                                                    <input checked={formData.is_working ? true : false} name='is_working' type="checkbox" onChange={onisworkingclick} /> Currently Working in {formData.companyname}
                                                </div>
                                            </div> : ''
                                    }

                                </div>
                                <div onClick={continuebtnlick} className='btn btn-primary mt-3 w-100'>Continue</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExperienceDetails