import React, { useState } from 'react'
import { Helmet } from 'react-helmet';
import { contact_form_service } from '../../ApiServices';
import { toast } from 'react-toastify';

const Contact = () => {

  const [formdata, setformdata] = useState({
    name: '',
    email: '',
    mobile: '',
    subject: '',
    message: '',
  });

  const [errors, seterrors] = useState({});

  const handlechange = (e) => {
    const { name, value } = e.target;

    seterrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));

    setformdata({
      ...formdata,
      [name]: value,
    });
  }

  const validateform = () => {
    const newErrors = {};

    // Validate username
    if (!formdata.name) {
      newErrors.name = 'Username is required';
    }

    // Validate email
    if (!formdata.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formdata.email)) {
      newErrors.email = 'Invalid email format';
    }

    // Validate mobile
    if (!formdata.mobile.trim()) {
      newErrors.mobile = 'Mobile number is required';
    }

    if (!formdata.subject) {
      newErrors.subject = 'Subject is required';
    }

    if (!formdata.message) {
      newErrors.message = 'Message is required';
    }

    seterrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  }


  const contactformsubmit = (e) => {
    e.preventDefault();
    if (validateform()) {
      contact_form_service(formdata).then((data) => {
        toast('✅'+ data.data.message);
        setformdata({
          name: '',
          email: '',
          mobile: '',
          subject: '',
          message: '',
        })
      }).catch((error) => { })
      console.log(errors);
    }
  }

  return (
    <>
      <Helmet>
        <title>
        Contact Us | EJOBEE
        </title>
        <meta name="description" content="Contact eJobee for any questions, support, or inquiries regarding job opportunities. Our dedicated team is here to assist you. Get in touch with us today!" />
      </Helmet>
      <section className='main-contact-us pb-0'>
        <div className="container gap-5 d-flex flex-column">
          <div className="row" style={{ rowGap: '10px' }}>
            <div className="col-lg-6">
              <a href='#' target='_blank' className="card">
                <div className="title">
                  <h5 className='d-flex align-items-center gap-1'> <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#2867B2" transform="matrix(1, 0, 0, 1, 0, 0)">

                    <g id="SVGRepo_bgCarrier" stroke-width="0" />

                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192" />

                    <g id="SVGRepo_iconCarrier"> <g id="style=fill"> <g id="email"> <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M7 2.75C5.38503 2.75 3.92465 3.15363 2.86466 4.1379C1.79462 5.13152 1.25 6.60705 1.25 8.5V15.5C1.25 17.393 1.79462 18.8685 2.86466 19.8621C3.92465 20.8464 5.38503 21.25 7 21.25H17C18.615 21.25 20.0754 20.8464 21.1353 19.8621C22.2054 18.8685 22.75 17.393 22.75 15.5V8.5C22.75 6.60705 22.2054 5.13152 21.1353 4.1379C20.0754 3.15363 18.615 2.75 17 2.75H7ZM19.2285 8.3623C19.5562 8.10904 19.6166 7.63802 19.3633 7.31026C19.1101 6.98249 18.6391 6.9221 18.3113 7.17537L12.7642 11.4616C12.3141 11.8095 11.6858 11.8095 11.2356 11.4616L5.6886 7.17537C5.36083 6.9221 4.88982 6.98249 4.63655 7.31026C4.38328 7.63802 4.44367 8.10904 4.77144 8.3623L10.3185 12.6486C11.3089 13.4138 12.691 13.4138 13.6814 12.6486L19.2285 8.3623Z" fill="#2867B2" /> </g> </g> </g>

                  </svg> Email</h5>
                </div>
                <p>yourejobee@gmail.com</p>
              </a>
            </div>
            <div className="col-lg-6">
              <div className="card">
                <div className="title">
                  <h5 className='d-flex align-items-center'>
                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.6211 8.45C19.5711 3.83 15.5411 1.75 12.0011 1.75C12.0011 1.75 12.0011 1.75 11.9911 1.75C8.46107 1.75 4.42107 3.82 3.37107 8.44C2.20107 13.6 5.36107 17.97 8.22107 20.72C9.28107 21.74 10.6411 22.25 12.0011 22.25C13.3611 22.25 14.7211 21.74 15.7711 20.72C18.6311 17.97 21.7911 13.61 20.6211 8.45ZM12.0011 13.46C10.2611 13.46 8.85107 12.05 8.85107 10.31C8.85107 8.57 10.2611 7.16 12.0011 7.16C13.7411 7.16 15.1511 8.57 15.1511 10.31C15.1511 12.05 13.7411 13.46 12.0011 13.46Z" fill="#2867B2" />
                    </svg>
                    Head Office</h5>
                </div>
                <p>C-21 Ress com, infront of nanakheda bus stand Ujjain</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <form onSubmit={contactformsubmit} className='contact-form'>
                <div className="form-group">
                  <input type="text" name='name' value={formdata.name} onChange={handlechange} className='form-control' placeholder='Enter Your Name' />
                  {errors.name && <span className="text-danger">{errors.name}</span>}
                </div>
                <div className="form-group">
                  <input type="text" name='email' value={formdata.email} onChange={handlechange} className='form-control' placeholder='Enter Your Email Address' />
                  {errors.email && <span className="text-danger">{errors.email}</span>}
                </div>
                <div className="form-group">
                  <input type="text" name='mobile' value={formdata.mobile} onChange={handlechange} className='form-control' placeholder='Enter Your Mobile Number' />
                  {errors.mobile && <span className="text-danger">{errors.mobile}</span>}
                </div>
                <div className="form-group">
                  <input type="text" name='subject' value={formdata.subject} onChange={handlechange} className='form-control' placeholder='Subject' />
                  {errors.subject && <span className="text-danger">{errors.subject}</span>}
                </div>
                <div className="form-group">
                  <textarea type="text" name='message' value={formdata.message} onChange={handlechange} className='form-control' placeholder='Message' />
                  {errors.message && <span className="text-danger">{errors.message}</span>}
                </div>
                <button type='submit' className="btn-primary btn">Submit</button>
              </form>
            </div>
            <div className="col-lg-6">
              <img src="/assets/images/contact-us.png" alt="EJOBEE" className='w-100' />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact