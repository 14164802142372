import React, { forwardRef, useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker'
import Select from 'react-select'
import 'react-datepicker/dist/react-datepicker.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { alldegree_service, candidate_complete_education_service, education_level_service, getalluniversityservice } from '../../ApiServices'
import {LanguageList} from '../../functional_lib/common_lib';

const EducationDetails = () => {

    //alert("i am in education details");

    const navigate = useNavigate();
    const location = useLocation();

    const [formData, setformData] = useState({
        englishlevel: "",
        educationlevel: "",
        university: "",
        degree: "",
        startDate: "",
        endDate: ""
    });
    const [errors, seterrors] = useState({})
    const [educationlevel, seteducationlevel] = useState([])
    const [universities, setuniversities] = useState([]);
    const [degrees, setdegrees] = useState([]);

    useEffect(() => {
        const id = new URLSearchParams(location.search).get('id');
        if (id == '' || id == null) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_id') == null) {
            navigate('/login');
        }

        if (localStorage.getItem('candidate_is_education_details') == 'true') {
            navigate('/')
        }
        getEducationlevel();
        getuniversities();
        getdegree();
    }, [])

    const getuniversities = () => {
        getalluniversityservice().then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setuniversities(mappedoptions);
        }).catch((error) => {

        })
    }

    const getdegree = () => {
        alldegree_service().then((data) => {
            const mappedoptions = data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            setdegrees(mappedoptions);
        }).catch((error) => { })
    }

    const getEducationlevel = () => {
        education_level_service().then((data) => {
        
            const mappedoptions = data.data.data.map((v) => ({
                value: v.id,
                label: v.name
            }));
            seteducationlevel(mappedoptions)
        }).catch((error) => { })
    }


    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className='border-bottom' onClick={onClick} ref={ref}>
            {value}
        </button>
    ));
    // date picker


    const handleChange = (event) => {
        const { name, value } = event.target
        seterrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
        setformData({
            ...formData,
            [name]: value
        })
    }
    const HandleOnSelectchange = (value, action) => {
        setformData({
            ...formData,
            [action.name]: value
        })
        seterrors((prevErrors) => ({
            ...prevErrors,
            [action.name]: '',
        }));
    }

    const formvalidate = () => {
        const newErrors = {};

        if (!formData.englishlevel) {
            newErrors.englishlevel = "English Level is Required";
        }

        if (!formData.educationlevel) {
            newErrors.educationlevel = "Education Level is Required";
        }
        //alert(formData.educationlevel.value);
        if (formData.educationlevel.value > 5) {
            // if (!formData.university) {
            //     newErrors.university = "University/college is Required";
            // }
            if(!formData.university_name){
                newErrors.university_name="University/college Required";
            }

            // if (!formData.degree) {
            //     newErrors.degree = "Degree is Required";
            // }
            if(!formData.degree_name){
                newErrors.degree_name="Degree Required";
            }

            if (!formData.startDate || !formData.endDate) {
                newErrors.fromtodate = "Start Year & Complete Date is Required";
            } else if (formData.startDate > formData.endDate) {
                newErrors.fromtodate = "";
            }
        }

        seterrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if there are no errors
    }

    const continueclick = () => {
        if (formvalidate()) {
            candidate_complete_education_service(formData).then((data) => {
                //alert("i m in candidate click");
                localStorage.setItem('candidate_is_education_details', 'true');
                navigate({ pathname: '/' })
            }).catch((eror) => { })
        }
    }

    const englishlevel =LanguageList();


    return (
        <div className='main-login'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-7 h-100">
                        <img className='w-100' src="/assets/images/personal-detail.png" alt="EJOBEE" />
                    </div>
                    <div className="col-lg-5">
                        <div className="login-box d-flex flex-column justify-content-center h-100">
                            <h2>Educational Details</h2>
                            <p>Fill Education Details for represent Your Profile</p>
                            <div className="login-form mt-2">
                                <div className="form-group">
                                    <div className="col-lg-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select English Level"
                                            isSearchable={false}
                                            name="englishlevel"
                                            value={formData.englishlevel}
                                            options={englishlevel}
                                            onChange={HandleOnSelectchange}
                                        />
                                        {errors.englishlevel && <span className="text-danger">{errors.englishlevel}</span>}
                                    </div>

                                    <div className="col-lg-12 mt-2">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="Select Education Level"
                                            isSearchable={false}
                                            name="educationlevel"
                                            value={formData.educationlevel}
                                            options={educationlevel}
                                            onChange={HandleOnSelectchange}
                                        />
                                        {errors.educationlevel && <span className="text-danger">{errors.educationlevel}</span>}
                                    </div>

                                    {
                                        formData.educationlevel.value > '5' ?
                                            <>
                                                <div className="col-lg-12 mt-2">
                                                <input
                    type="text"
                    name='university_name'
                    value={formData.university_name}
                    class="form-control"
                    placeholder="University"
                    onChange={handleChange}
                  />

                                                    {/* <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        placeholder="Select University/college"
                                                        isSearchable={true}
                                                        name="university"
                                                        value={formData.university}
                                                        options={universities}
                                                        onChange={HandleOnSelectchange}
                                                    /> */}
                                                    {errors.university_name && <span className="text-danger">{errors.university_name}</span>}
                                                </div>
                                                <div className="col-lg-12 mt-2">
                                                <input
                    type="text"
                    name='degree_name'
                    value={formData.degree_name}
                    class="form-control"
                    placeholder="Degree"
                    onChange={handleChange}
                  />
                                                    {/* <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        placeholder="Select Degree"
                                                        isSearchable={true}
                                                        value={formData.degree}
                                                        name="degree"
                                                        options={degrees}
                                                        onChange={HandleOnSelectchange}
                                                    /> */}
                                                    {errors.degree_name && <span className="text-danger">{errors.degree_name}</span>}
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-6 mt-3 d-flex flex-column">
                                                        <label className='me-2'>Start Date</label>
                                                        <ReactDatePicker selected={formData.startDate == "" ? new Date() : formData.startDate}
                                                            showMonthYearPicker
                                                            className='form-control' dateFormat="MMMM yyyy" placeholderText='Start Year' onChange={(date) => {
                                                                setformData({
                                                                    ...formData,
                                                                    startDate: date
                                                                })
                                                            }} maxDate={new Date()} customInput={<ExampleCustomInput />} />
                                                    </div>
                                                    <div className="col-lg-6 mt-3 d-flex flex-column">
                                                        <label className='me-2'>end Date</label>
                                                        <ReactDatePicker selected={formData.endDate == "" ? new Date() : formData.endDate}
                                                            showMonthYearPicker
                                                            className='form-control'
                                                            dateFormat="MMMM yyyy"
                                                            placeholderText='Start Year'
                                                            onChange={(date) => {
                                                                setformData({
                                                                    ...formData,
                                                                    endDate: date
                                                                })
                                                            }}
                                                            customInput={<ExampleCustomInput />} />
                                                    </div>
                                                    {errors.fromtodate && <span className="text-danger">{errors.fromtodate}</span>}
                                                </div>
                                            </> :
                                            ''
                                    }
                                </div>
                                <div onClick={continueclick} className='btn btn-primary mt-3 w-100'>Continue</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EducationDetails