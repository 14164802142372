import React, { useEffect, useState } from 'react'
import { admincandidate_service } from '../../ApiServices';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {Comp_Student_Profile_View} from '../../web/ash_dialogue/comp_student_profile_view';
// Ash Codes
import {getCondidatesView} from '../../ApiServices';

const AdminCandidate = () => {

  //alert("i am in candidate");
  const navigate = useNavigate();

  const [candidates, setCandidates] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  
  const [stCandidateId,setStCandidateId]=useState(null);

  const setCandidateId=(item)=>{
    setStCandidateId(item.id);
  }


 
  const fetchcandidate = () => {
    if (hasMore) {
      getCondidatesView([],"getCandidateOutStandingView").then((res) => {
        
        const newCandidates = JSON.parse(res.data);
        if(newCandidates.length===0){
          setHasMore(false);
        }
        setCandidates(prevCandidates => [...prevCandidates, ...newCandidates]);
        setHasMore(res.data.current_page < res.data.last_page);
        setPage(prevPage => prevPage + 1);
      }).catch((error) => {
        toast('❌' + error.message);
        if (error.message === 'Unauthorized: Please log in.') {
          localStorage.clear();
          navigate('/')
          window.location.reload();
        }
      })
    }
  }
  //alert(candidates.length);

  useEffect(() => {
    fetchcandidate();
  }, [])

  const setLoginAsCandidate=(item)=>{
    //  alert(JSON.stringify(item));
    //  return;
    localStorage.setItem('candidate_is_profile_completed',item.is_profile_completed);
    localStorage.setItem('candidate_is_education_details',item.is_education_detail);
    localStorage.setItem('candidate_is_personal_details',item.is_personal_detail);
    localStorage.setItem('candidate_is_experience_details',item.is_experience_detail);
    localStorage.setItem('candidate_is_preference_details',item.is_preference_detail);
    localStorage.setItem('candidate_id',item.id);
    navigate('/profile');
  }


  return (<>
    <div className="container-fluid">
      <div class="card shadow border-0 mb-7">
        <div class="card-header">
          <h5 class="mb-0">Candidate</h5>
        </div>
        <div class="table-responsive">
          <InfiniteScroll
            dataLength={candidates.length}
            next={fetchcandidate}
            hasMore={hasMore}
            loader={<div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '30px 0px' }}>
              <div style={{ width: '20px', height: '20px' }} class="loading-loader"></div>
            </div>}
          // endMessage={<p>No more candidates to show.</p>}
          >
            <table class="table table-bordered table-hover text-sm text-nowrap1 table-responsive">
              <thead class="thead-light">
                <tr>
                  <th>S.No.</th>
                  <th >Reg. Date</th>
                  <th >Name</th>
                  <th >mobile</th>
                  <th >Email</th>
                  <th>City</th>
                  <th >No. Job Applied</th>
                  <th >No. Job Saved</th>
                  <th >Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  candidates.map((v,index) => {
                    return (
                      <tr>
                        <td>{index+1}</td>
                        <td>{v.created_at}</td>
                        <td>
                          {v.profilepic ?
                            <img alt="..." src="https://images.unsplash.com/photo-1612422656768-d5e4ec31fac0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80" class="avatar avatar-sm rounded-circle me-2" /> :
                            <img src='/assets/images/user_icon.png' class="avatar avatar-sm rounded-circle me-2" />
                          }
                          {
                          v.firstname==null?
                          '': v.firstname + " " + v.lastname
                        }
                        </td>
                        <td>
                          {v.mobile}
                        </td>
                        <td>
                          {v.email}
                        </td>
                        <td>
                          {v.city?.name}
                        </td>
                        <td>{v.count_applied_jobs}</td>
                        <td>{v.count_saved_jobs}</td>
                        <td></td>
                        <td class="text-end">
                        <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-bs-toggle="dropdown" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                    <a class="dropdown-item"  onClick={()=>setCandidateId(v)} data-bs-toggle="modal" data-bs-target="#divStudentProfileView" href="javascript:void(0)">View Profile</a>
                    <a class="dropdown-item" onClick={()=>setLoginAsCandidate(v)}  href="javascript:void(0)">Login As Candidate</a>

                    </div>
                  </div>
                          {/* <button onClick={()=>setCandidateId(v)} data-bs-toggle="modal" data-bs-target="#divStudentProfileView" class="btn btn-sm btn-neutral">View</button> */}
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
    <Comp_Student_Profile_View candidateId={stCandidateId}/>
  </>)
}

export default AdminCandidate