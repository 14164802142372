import React from 'react'
import { Helmet } from 'react-helmet'

const Terms = () => {
  return (
    <>
    <Helmet>
      <title>Terms and Conditions - Guidelines for Using ejobee</title>
      <meta name="description" content="Review ejobee's Terms and Conditions for a comprehensive understanding of platform usage. Navigate guidelines, responsibilities, and user agreements to ensure a secure and respectful experience for all users." />
    </Helmet>
      <section>
        <div className="container">
          <div className="section-title mb-3">
            <h4>Terms And Conditions</h4>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <h4>Acceptance of Terms</h4>
              <p>By accessing or using the eJobee Job Platform with Resume Builder ("the Platform"), you agree to be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use the Platform.</p>

              <h4>Use of the Platform</h4>
              <p>
                - You must be at least 18 years old to use the Platform.<br />
                - You are responsible for maintaining the confidentiality of your account information.<br />
                - You agree not to use the Platform for any illegal or unauthorized purpose.
              </p>

              <h4>User Accounts</h4>
              <p>
                - To access certain features of the Platform, you may be required to create a user account.<br />
                - You are responsible for all activities that occur under your account.<br />
                - You agree to provide accurate and complete information during the registration process.
              </p>

              <h4>Intellectual Property</h4>
              <p>
                - The content and features of the Platform are protected by copyright, trademark, and other intellectual property laws.<br />
                - You may not modify, reproduce, or distribute any content from the Platform without our written permission.
              </p>

              <h4>Privacy</h4>
              <p>Your use of the Platform is also governed by our Privacy Policy. By using the Platform, you consent to the terms of the Privacy Policy.</p>

              <h4>Termination</h4>
              <p>We reserve the right to terminate or suspend your account and access to the Platform for any reason without prior notice.</p>

              <h4>Limitation of Liability</h4>
              <p>
                - We are not responsible for any loss or damage resulting from your use of the Platform.<br />
                - In no event shall eJobee be liable for any indirect, consequential, or incidental damages.
              </p>

              <h4>Governing Law</h4>
              <p>These Terms and Conditions are governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.</p>

              <h4>Changes to Terms</h4>
              <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and your continued use of the Platform after such changes constitutes your acceptance of the new terms.</p>

              <h4>Contact Us</h4>
              <p>If you have any questions about these Terms and Conditions, please contact us at <a href="#" style={{ color: 'blue' }} target="_blank">yourejobee@gmail.com</a> </p>

              <p>Last Updated: 25 Jan 2024</p>

              <p>Thank you for using eJobee!</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Terms