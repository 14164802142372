import $, { extend } from 'jquery';
import axios from "axios"
import { add_work_experience_url, addeducationdetail_url, admin_changepassword_url, admin_login_url, adminalljobs_url, admincandidate_url, admincompany_url, alljobs_url, applicationlist_url, applyjob_url, base_url, blog_post_url, buy_premium_url, candidate_education_details_url, candidate_experience_url, candidate_personal_details_url, candidate_preferences_url, candidate_profile_url, candidate_resend_otp_url, candidate_send_otp, candidate_verify_otp_url, city_url, citybystate_url, company_completeprofileurl, company_profileurl, company_sendotp_url, company_updateprofileurl, company_verifyotp_url, contact_form_url, country_url, currency_url, dashboarddata_url, degree_url, deleteeducationdetail_url, deleteexperiecnce_url, edit_current_salary_url, edit_total_experience_url, edit_work_experience_url, editeducationdetailbyid_url, education_level_url, educationdetailbyid_url, freejobpostpublish_url, industry_url, job_role_url, jobdetailbyslug_url, jobplan_url, jobshift_url, jobtype_url, language_url, latestpublishjob_url, perks_url, postcandidaterequirement_url, postinterviewerdetail_url, postjobdetail_url, postjobpreview_url, postupdatejobdetail_url, savejob_url, savejoblist_url, searchcandidate_url, skills_url, state_url, statebycountry_url, university_url, updatebasicdetail_url, updateeducationlevel_url, updateenglishlevel_url, updatelanguage_url, updatelocation_url, updateprefences_url, updateskill_url, upload_introvideo_url, upload_profilpic_url, upload_resume_url, workexperiencebyid_url, worktype_url } from "./ApiUrl";
import {ash_base_url} from "./ApiUrl";
import {ajaxindicatorstart,ajaxindicatorstop} from './functional_lib/common_lib';


const adminbearerToken = localStorage.getItem('admin-token')
const headers = {
    'Authorization': `Bearer ${adminbearerToken}`,
    'Content-Type': 'application/json', // Adjust the content type based on your API requirements
};

//Ash Codes

// End of Ash Codes

// admin login

export const getMultiPartData=async(frmData,video,uriCuri)=>{
    const body = new FormData();
    //alert(JSON.stringify(frmData));

    try {
        body.append("video_fl",video);
        body.append("ser_candidate_id",frmData.ser_candidate_id);
        
        const data = await axios.post(ash_base_url + uriCuri,body);
        //alert(JSON.stringify(data));
        var checkData=$($($.parseHTML(data.data)).filter("#resP")).text();
        var checkMsg=$($($.parseHTML(data.data)).filter("#resPS")).text();
        let response={data:checkData,msg:checkMsg};
        return response;
    } catch (error) {
        //alert(error);
        if (error.response && error.response.status === 401) {
            // Throw a custom error with a message
            throw new Error('Unauthorized access.');
        } else {
            // Throw the original error to be caught by the calling code
            throw error;
        }
    }
}

export const getCondidatesView=async(frmData,uriCuri)=>{
    try {
        ajaxindicatorstart("Loading data..");
        //alert()
        //alert(JSON.stringify(frmData));
        //alert(ash_base_url);
        const data = await axios.post(ash_base_url + uriCuri,frmData);
        //alert(data.data);
        var checkData=$($($.parseHTML(data.data)).filter("#resP")).text();
        var checkMsg=$($($.parseHTML(data.data)).filter("#resPS")).text();
        let response={data:checkData,msg:checkMsg};
        ajaxindicatorstop();
        return response;
    } catch (error) {
        ajaxindicatorstop();
        //alert(error);
        if (error.response && error.response.status === 401) {
            // Throw a custom error with a message
            throw new Error('Unauthorized access.');
        } else {
            // Throw the original error to be caught by the calling code
            throw error;
        }
    }
}
export const admin_login = async (formdata) => {
    try {
        const data = await axios.post(base_url + admin_login_url, formdata);
        return data;
    } catch (error) {
        if (error.response && error.response.status === 401) {
            // Throw a custom error with a message
            throw new Error('Unauthorized access.');
        } else {
            // Throw the original error to be caught by the calling code
            throw error;
        }
    }
}

// admin change password
export const admin_changepassword = async (postdata) => {
    if (adminbearerToken) {
        try {
            const response = await axios.post(base_url + admin_changepassword_url, postdata, { headers: headers });
            return response;
        } catch (error) {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// admin dashboard data
export const admindashboarddata_service = async () => {
    if (adminbearerToken) {
        try {
            const response = await axios.get(base_url + dashboarddata_url, { headers: headers });
            return response;
        } catch (error) {
            if (!error.response) {
                // Network error
                throw new Error('Network error');
            } else {
                // HTTP status code is not 2xx
                const status = error.response.status;
                if (status === 401) {
                    throw new Error('Unauthorized: Please log in.');
                } else if (status === 403) {
                    throw new Error('Forbidden: Not Permitted');
                } else if (status === 404) {
                    throw new Error('Not Found');
                } else if (status === 500) {
                    throw new Error('Server Error: Please try again later.');
                } else {
                    // Other errors
                    throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
                }
            }
        }
    }
}


// admin candidate
export const admincandidate_service = async (page) => {
    alert(admincandidate_url);
    if (adminbearerToken) {
        try {
            const data = await axios.get(base_url + admincandidate_url, { headers: headers, params: { page } })
            return data
        } catch (error) {
            if (!error.response) {
                // Network error
                throw new Error('Network error');
            } else {
                // HTTP status code is not 2xx
                const status = error.response.status;
                if (status === 401) {
                    throw new Error('Unauthorized: Please log in.');
                } else if (status === 403) {
                    throw new Error('Forbidden: Not Permitted');
                } else if (status === 404) {
                    throw new Error('Not Found');
                } else if (status === 500) {
                    throw new Error('Server Error: Please try again later.');
                } else {
                    // Other errors
                    throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
                }
            }
        }
    }
}


// admin companies
export const admincompanies_service = async (page) => {
    if (adminbearerToken) {
        try {
            const data = await axios.get(base_url + admincompany_url, { headers: headers, params: { page } })
            return data;
        } catch (error) {
            if (!error.response) {
                // Network error
                throw new Error('Network error');
            } else {
                // HTTP status code is not 2xx
                const status = error.response.status;
                if (status === 401) {
                    throw new Error('Unauthorized: Please log in.');
                } else if (status === 403) {
                    throw new Error('Forbidden: Not Permitted');
                } else if (status === 404) {
                    throw new Error('Not Found');
                } else if (status === 500) {
                    throw new Error('Server Error: Please try again later.');
                } else {
                    // Other errors
                    throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
                }
            }
        }
    }
}

// admin all jobs
export const adminalljob_service = async (page) => {
    //alert(base_url+adminalljobs_url);
    if (adminbearerToken) {
        try {
            const data = await axios.get(base_url + adminalljobs_url, { headers: headers, params: { page } })
            return data;
        } catch (error) {
            if (!error.response) {
                // Network error
                throw new Error('Network error');
            } else {
                // HTTP status code is not 2xx
                const status = error.response.status;
                if (status === 401) {
                    throw new Error('Unauthorized: Please log in.');
                } else if (status === 403) {
                    throw new Error('Forbidden: Not Permitted');
                } else if (status === 404) {
                    throw new Error('Not Found');
                } else if (status === 500) {
                    throw new Error('Server Error: Please try again later.');
                } else {
                    // Other errors
                    throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
                }
            }
        }
    }
}


// candidate send-otp
export const candidate_send_otp_service = async (mobile) => {
    try {
        const response = await axios.post(base_url + candidate_send_otp, {
            mobile: mobile
        });
        return response;
    } catch (error) {
        return error
    }
}

// resend otp
export const candidate_resend_otp = async (mobile) => {
    try {
        const response = await axios.post(base_url + candidate_resend_otp_url, {
            mobile: mobile
        });
        return response;
    } catch (error) {
        return error
    }
}


// verify otp
export const candidate_verify_otp = async (mobile, otp) => {
    //alert("i m in verify otp")
    try {
        const response = await axios.post(base_url + candidate_verify_otp_url, {
            mobile: mobile,
            otp: otp
        });
        //alert(JSON.stringify(response));
        return response;
    } catch (error) {
        //alert(JSON.stringify(error));
        return error
    }
}

// complete profile

// candidate personal detail
export const candidate_personal_detail = async (formdata) => {
    const body = {
        id: localStorage.getItem('candidate_id'),
        firstname: formdata.firstname,
        lastname: formdata.lastname,
        email: formdata.email,
        gender: formdata.gender,
        dob: formdata.dob,
        country_id: formdata.country.value,
        state_id: formdata.state.value,
        //city_id: formdata.city.value
        city_id:1,
        city_name:formdata.city_name,
    }
    try {
        const data = await axios.post(base_url + candidate_personal_details_url, body);
        return data;
    } catch (error) {
        //alert("i mi in error");
        return error
    }
}

// complete candidate education
export const candidate_complete_education_service = async (formdata) => {

    const body = {
        id: localStorage.getItem('candidate_id'),
        english_level: formdata.englishlevel.value,
        education_level_id: formdata.educationlevel.value,
        //university_id: formdata.university.value,
        university_id:1,
        university_name:formdata.university_name,
        //degree_id: formdata.degree.value,
        degree_id:1,
        degree_name:formdata.degree_name,
        start: formdata.startDate,
        end: formdata.endDate
    }
    try {
        const data = await axios.post(base_url + candidate_education_details_url, body);
        return data;
    } catch (error) {
        return error
    }
}

// complete candidate experience
export const complete_experience_service = async (formdata) => {
    const body = {
        id: localStorage.getItem('candidate_id'),
        is_experienced: formdata.is_experience == 'yes' ? 'true' : 'false',
        total_experience: formdata.total_experience.value,
        company_name: formdata.companyname,
        //jobrole_id: formdata.jobrole.value,
        jobrole_id:1,
        jobrole_name:formdata.jobrole_name,
        is_working: formdata.is_working,
        start: formdata.startDate,
        end: formdata.endDate
    }
    try {
        const data = await axios.post(base_url + candidate_experience_url, body);
        return data
    } catch (error) {
        return error
    }
}


// complete candidate preferece 
export const complete_preference_detail = async (formdata) => {
    const body = {
        id: localStorage.getItem('candidate_id'),
        //city_id: formdata.jobcity.value,
        city_id:1,
        city_name:formdata.city_name,
        worktype_id: formdata.worktype.value,
        jobtype_id: formdata.jobtype.value,
        jobshift_id: formdata.jobshift.value
    }
    try {
        const data = await axios.post(base_url + candidate_preferences_url, body);
        return data
    } catch (error) {
        return error;
    }
}

// getprofile
export const candidateprofile_service = async () => {
    const body = {
        candidate_id: localStorage.getItem('candidate_id')
    }
    try {
        const data = await axios.post(base_url + candidate_profile_url, body);
        return data
    } catch (error) {
        return error
    }
}

// edit total experience
export const edit_total_experience_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + edit_total_experience_url,
            {
                id: localStorage.getItem('candidate_id'),
                total_experience: formdata.total_experience.value
            });
        return data;
    } catch (error) {
        return error
    }
}

// edit current salary
export const edit_current_salary_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + edit_current_salary_url,
            {
                id: localStorage.getItem('candidate_id'),
                current_salary: formdata.currentsalary
            });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// add work experience
export const addworkexperience_service = async (formdata) => {
    //alert(JSON.stringify(formdata));
    const body = {
        id: localStorage.getItem('candidate_id'),
        company_name: formdata.companyname,
        jobrole_id: 1,
        jobrole: formdata.jobrole,
        is_working: formdata.is_working,
        start: formdata.startDate,
        end: formdata.endDate
    }
    try {
        const data = await axios.post(base_url + add_work_experience_url, body);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// edit work experience
export const editworkexperience_service = async (formdata, expid) => {
    
    const body = {
        experience_id: expid,
        company_name: formdata.companyname,
        //jobrole_id: formdata.jobrole.value,
        jobrole_id: 1,
        jobrole_name:formdata.jobrole,
        is_working: formdata.is_working,
        start: formdata.startDate,
        end: formdata.endDate
    }
    //alert(JSON.stringify(body));
    try {
        const data = await axios.post(base_url + edit_work_experience_url, body);
        //alert(JSON.stringify(data));
        return data
    } catch (error) {
//alert(JSON.stringify(error));
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// work experince by id
export const getworkexperiencebyid_service = async (expid) => {
    try {
        const data = await axios.get(base_url + workexperiencebyid_url + expid);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// delete work experience
export const deleteworkexperience_service = async (expid) => {
    const body = {
        experience_id: expid
    }
    try {
        const data = await axios.post(base_url + deleteexperiecnce_url, body);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// upload resume 
export const uploadresume_service = async (resume) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    body.append('resume', resume);
    try {
        const data = axios.post(base_url + upload_resume_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// candidate upload profile
export const upload_profilepic_service = async (profilepic) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    body.append('profilepic', profilepic);
    try {
        const data = await axios.post(base_url + upload_profilpic_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// candidate upload intro video
export const uploadintrovideo_service = async (video) => {
    const body = new FormData();
    body.append('profilepic', video);
    try {
        alert(upload_introvideo_url);
        //const data = await axios.post(base_url + upload_introvideo_url + localStorage.getItem('candidate_id'),body)
        const data = await axios.post(base_url + upload_introvideo_url,body)
        alert(JSON.stringify(data));
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// candidate uppdate english level
export const update_english_level = async (data) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    body.append('english_level', data.englishlevel.value)
    try {
        const data = await axios.post(base_url + updateenglishlevel_url, body);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// candidate update education level
export const update_education_level = async (data) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    body.append('education_level_id', data.educationlevel.value);
    try {
        const data = axios.post(base_url + updateeducationlevel_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// candidate add education
export const add_candidateeducation_service = async (formdata) => {
    const body = {
        id: localStorage.getItem('candidate_id'),
        //university_id: formdata.university.value,
        univsersity_id:1,
        university_name:formdata.university_name,

        //degree_id: formdata.degree.value,
        degree_id:1,
        degree_name:formdata.degree_name,

        start: formdata.startDate,
        end: formdata.endDate
    }
    try {
        const data = await axios.post(base_url + addeducationdetail_url, body);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// candidate get education by id
export const geteducationdetailbyid_service = async (id) => {
    try {
        //alert(id);
        const data = await axios.get(base_url + educationdetailbyid_url + id);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// candidate edit education by id
export const editeducationdetailbyid_service = async (formdata, id) => {
    const body = {
        education_id: id,
        //university_id: formdata.university.value,
        university_id:1,
        university_name:formdata.university_name,

        //degree_id: formdata.degree.value,
        degree_id:1,
        degree_name:formdata.degree_name,
        start: formdata.startDate,
        end: formdata.endDate
    }
    try {
        const data = await axios.post(base_url + editeducationdetailbyid_url, body);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}



// candidate delete education by id
export const deleteeducationbyid_service = async (id) => {
    const body = {
        education_id: id
    }
    try {
        const data = await axios.post(base_url + deleteeducationdetail_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}



// candidate update skills
export const candidateaddskill_service = async (formdata) => {
    const body = {
        id: localStorage.getItem('candidate_id'),
        skill: formdata.skills
    }
    try {
        const data = await axios.post(base_url + updateskill_url, body);
        return data;
    } catch (error) {
        return error;
    }
}


// candidate update languages
export const updatelanguage_service = async (formdata) => {
    const body = {
        id: localStorage.getItem('candidate_id'),
        language: formdata.language
    }
    try {
        const data = await axios.post(base_url + updatelanguage_url, body);
        return data;
    } catch (error) {
        return error
    }
}


// candidate update locations
export const update_location = async (formdata) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    body.append('country_id', formdata.country.value);
    body.append('state_id', formdata.state.value)
    //body.append('city_id', formdata.city.value)
    body.append('city_id', 1)
    body.append('city_name',formdata.city_name);

    try {
        const data = await axios.post(base_url + updatelocation_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// candidate update job preferecnces
export const update_job_prefence = async (formdata) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    //body.append('city_id', formdata.pref_city.value);
    body.append('city_id',1);
    body.append('city_name',formdata.city_name);
    body.append('worktype_id', formdata.pref_worktype.value);
    body.append('jobtype_id', formdata.pref_jobtype.value);
    body.append('jobshift_id', formdata.pref_jobshift.value);

    try {
        const data = axios.post(base_url + updateprefences_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// candidate update basic detail
export const update_basic_detail = async (formdata) => {
    const body = new FormData();
    body.append('id', localStorage.getItem('candidate_id'));
    body.append("firstname", formdata.firstname);
    body.append("lastname", formdata.lastname);
    body.append("email", formdata.email);
    body.append("gender", formdata.gender);
    body.append("dob", formdata.dob);
    try {
        const data = axios.post(base_url + updatebasicdetail_url, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// latest published job

export const latestpublishedjob_service = async () => {
    try {
        const data = await axios.get(base_url + latestpublishjob_url);
        return data;
    } catch (error) {
        return error
    }
}

// all published job
export const alljobs_service = async (jobroleid, jobcityid) => {
    try {
        //alert(base_url + alljobs_url);
        const data = await axios.get(base_url + alljobs_url, {
            params: {
                jobrole_id: "",
                jobcity_id: "",
                candidate_id: ""
            }
        });
        return data;
    } catch (error) {
        alert(error);
        if (!error.response) {
            throw new Error('Network error');
        } else {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// job detail by slug

export const jobdetailbyslug_sevice = async (slug) => {
    if (localStorage.getItem('candidate_id')) {
        try {
            const data = await axios.get(base_url + jobdetailbyslug_url + slug, {
                params: {
                    candidate_id: localStorage.getItem('candidate_id')
                }
            });
            return data;
        } catch (error) {
            return error
        }
    } else {
        try {
            const data = await axios.get(base_url + jobdetailbyslug_url + slug);
            return data;
        } catch (error) {
            return error
        }
    }
}

// apply job 
export const applyjob_service = async (jobid) => {
    const formdata = {
        candidate_id: localStorage.getItem('candidate_id'),
        job_id: jobid
    }
    try {
        const data = await axios.post(base_url + applyjob_url, formdata);
        return data;
    } catch (error) {
        if (!error.response) {
            throw new Error('Network error');
        } else {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// save job
export const savejob_service = async (jobid) => {
    const formdata = {
        candidate_id: localStorage.getItem('candidate_id'),
        job_id: jobid
    }
    try {
        const data = await axios.post(base_url + savejob_url, formdata);
        return data;
    } catch (error) {
        if (!error.response) {
            throw new Error('Network error');
        } else {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


export const savejoblist_service = async () => {
    try {
        //alert(savejoblist_url);
        const data = await axios.get(base_url + savejoblist_url + localStorage.getItem('candidate_id'));
        return data;
    } catch (error) {
        if (!error.response) {
            throw new Error('Network error');
        } else {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// unsave job
export const unsavejob_service = async () => {

}


// application list
export const applicationlist_service = async () => {
    try {
        const data = await axios.get(base_url + applicationlist_url + localStorage.getItem('candidate_id'));
        return data;
    } catch (error) {
        if (!error.response) {
            throw new Error('Network error');
        } else {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// candidate apply job



// candidate save job


// candidate get all saved job


// candidate get all applied job


// candidate applied job status


// candidate notification


// company send otp

export const company_sendotp = async (mobile) => {
    try {
        const response = await axios.post(base_url + company_sendotp_url, {
            mobile: mobile
        });
        return response;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company verify otp

export const company_verifyotp = async (mobile, otp) => {
    try {
        const response = await axios.post(base_url + company_verifyotp_url, {
            mobile: mobile,
            otp: otp
        });
        return response
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company complete profile
export const compnay_complete_profile_service = async (formdata) => {
    try {
        if (localStorage.getItem('company_id')) {
            const data = await axios.post(base_url + company_completeprofileurl,
                {
                    id: localStorage.getItem('company_id'),
                    firstname: formdata.firstname,
                    lastname: formdata.lastname,
                    gender: formdata.gender,
                    email: formdata.email,
                    companyname: formdata.companyname,
                    no_of_employees: formdata.numberemployee.value,
                    country: formdata.country.value,
                    state: formdata.state.value,
                    //city: formdata.city.value,
                    city:1,
                    city_name:formdata.city_name,

                    is_profile_completed: "true"
                }
            )
            return data;
        }
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company profile
export const company_profile_service = async (id) => {
    try {
        const data = await axios.post(base_url + company_profileurl,
            {
                id: id
            }
        )
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company update profile
export const company_update_profile_service = async (formdata) => {
    const body = {
        id: localStorage.getItem('company_id'),
        firstname: formdata.firstname,
        lastname: formdata.lastname,
        gender: formdata.gender,
        email: formdata.email,
        companyname: formdata.companyname,
        no_of_employees: formdata.numberemployees.value,
        country: formdata.country.value,
        state: formdata.state.value,
        //city: formdata.city.value,
        city:1,
        city_name:formdata.city_name
    }
    try {
        const data = await axios.post(base_url + company_updateprofileurl, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company get candidate



// company get all jobs
export const companygetalljob_service = async (companyid) => {
    try {
        const data = await axios.get(base_url + 'company/' + companyid + '/jobs')
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company get job by id
export const companyjobsbyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + 'company/jobs/' + id);
        return data
    } catch (error) {
        return error
    }
}


// company postjob

// post job details
export const postjbdetail_service = async (formdata) => {
    const body = {
        company_id: formdata.companyid,
        //jobrole_id: formdata.jobtitle.value,
        jobrole_id: 0,
        jobrole_name:formdata.jobrole_name,

        //industry_id: formdata.industry.value,
        industry_id:0,
        industry_name:formdata.industry_name,

        jobtype_id: formdata.jobtype.value,
        is_night: formdata.isnightshift,
        worktype_id: formdata.worktype.value,
        office_address: formdata.officeaddress,
        //jobcity_id: formdata.jobcity.value,
        jobcity_id:0,
        city_name:formdata.city_name,
        min_salary: formdata.minsalary,
        max_salary: formdata.maxsalary,
        perks: formdata.perks,
        is_fees: formdata.isfee,
        fee_amount: formdata.feeamount
    }
    try {
        const data = await axios.post(base_url + postjobdetail_url, body);
        return data;
    } catch (error) {
        //alert(JSON.stringify(error));
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


export const postupdatejobdetail_service = async (formdata, id) => {
    const body = {
        //jobrole_id: formdata.jobtitle.value,
        jobrole_id:0,
        jobrole_name:formdata.jobrole_name,
        
        //industry_id: formdata.industry.value,
        industry_id:0,
        industry_name:formdata.industry_name,

        jobtype_id: formdata.jobtype.value,
        is_night: formdata.isnightshift,
        worktype_id: formdata.worktype.value,
        office_address: formdata.officeaddress,
        //jobcity_id: formdata.jobcity.value,
        jobcity_id: 0,
        city_name:formdata.city_name,
        min_salary: formdata.minsalary,
        max_salary: formdata.maxsalary,
        perks: formdata.perks,
        is_fees: formdata.isfee,
        fee_amount: formdata.feeamount
    }
    try {
        const data = await axios.post(base_url + postupdatejobdetail_url + id, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company post candidate requirement
export const postcandidaterequirement_service = async (id, formdata) => {
    const body = {
        'education_level_id': formdata.educationlevel.value,
        //'degree_id': formdata.degree.value,
        'degree_id':1,
        'degree_name':formdata.degree_name,
        'experience': formdata.experiencetype,
        'exp_year': formdata.experience_year,
        'english_level': formdata.englishlevel,
        'gender': formdata.gender,
        'minage': formdata.minage,
        'maxage': formdata.maxage,
        'skills': formdata.skills,
        'job_description': formdata.jobdescription,
    }
    try {
        const data = await axios.post(base_url + postcandidaterequirement_url + id, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// pos interviewer information
export const postinterviewerinformation_service = async (id, formdata) => {
    try {
        const body = {
            'recruiter_name': formdata.recruitername,
            'recruiter_whatsapp': formdata.recruiterwhatsapp,
            'recruiter_email': formdata.recruiteremail,
            'interview_type': formdata.interviewtype,
            'interview_address': formdata.interviewaddress,
        };
        const data = await axios.post(base_url + postinterviewerdetail_url + id, body)
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// company job preview
export const postjobpreview_service = async (id) => {
    try {
        const data = await axios.post(base_url + postjobpreview_url + id);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// post free job publish job

export const freejobpost_service = async (id, formdata) => {
    const body = {
        plan_id: formdata.planid
    }
    try {
        const data = await axios.post(base_url + freejobpostpublish_url + id, body);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// company search notification
export const companysearchcandidate_service = async () => {
    try {
        const data = await axios.get(base_url + searchcandidate_url);
        return data;
    } catch (error) {
        return error
    }
}


// company notification




// add country
export const addcountryservice = async (formbody) => {
    try {
        const data = axios.post(base_url + country_url, formbody, { headers: headers });
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get country
export const getcountryservice = async () => {
    try {
        const data = await axios.get(base_url + country_url);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get country by id
export const getcountrybyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + country_url + '/' + id);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// edit country
export const updatecountrybyid_service = async (formbody, id) => {

    try {
        const data = await axios.put(base_url + country_url + '/' + id, formbody, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// add state 
export const addstateservice = async (formdata) => {
    const formbody = {
        country_id: formdata.country.value,
        name: formdata.name
    }
    try {
        const data = await axios.post(base_url + state_url, formbody, { headers: headers })
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get all state
export const getallstateservice = async () => {
    try {
        const data = await axios.get(base_url + state_url);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get state by country
export const getstateservice = async (countryid) => {
    try {
        const data = await axios.get(base_url + statebycountry_url + countryid);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get state by id 
export const getstatebyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + state_url + '/' + id);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// update state by id
export const updatestatebyid_service = async (formdata, id) => {
    const formbody = {
        country_id: formdata.country.value,
        name: formdata.name
    }
    try {
        const data = await axios.put(base_url + state_url + "/" + id, formbody, { headers: headers })
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// get all city
export const getallcityservice = async () => {
    try {
        const data = await axios.get(base_url + city_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// get city by id
export const getcitybyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + city_url + "/" + id);
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// update city by id
export const updatecitybyid_service = async (formdata, id) => {
    const formbody = {
        state_id: formdata.state.value,
        name: formdata.name
    }
    try {
        const data = await axios.put(base_url + city_url + "/" + id, formbody, { headers: headers });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// add city
export const addcityservice = async (formdata) => {
    const formbody = {
        state_id: formdata.state.value,
        name: formdata.name
    }
    try {
        const data = await axios.post(base_url + city_url, formbody, { headers: headers });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get city by state
export const getcityservice = async (stateid) => {
    try {
        const data = await axios.get(base_url + citybystate_url + '/' + stateid);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// all blog post
export const blog_post_service = async (page) => {
    try {
        const data = await axios.get(base_url + blog_post_url + '?page=' + page);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// add blog post
export const addblogpost_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + blog_post_url, formdata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${adminbearerToken}` // Include this if needed
            }
        });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get blog post by id
export const blogpostbyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + blog_post_url + "/" + id);
        return data;
    } catch (error) {
        return error
    }
}

// edit blog post
export const edit_blog_post_service = async (id, formdata) => {
    try {
        const data = await axios.put(base_url + blog_post_url + '/' + id, formdata, { headers: headers });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// delete blog post
export const delete_blog_post = async (id) => {
    try {
        const data = await axios.delete(base_url + blog_post_url + '/' + id);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

//post contact form
export const contact_form_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + contact_form_url, formdata);
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get all contact form
export const allcontact_form = async () => {
    try {
        const data = await axios.get(base_url + contact_form_url, { headers: headers });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// add educatin level
export const add_education_level_service = async (formbody) => {
    try {
        const data = await axios.post(base_url + education_level_url, formbody, { headers: headers })
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}

// get all education level
export const education_level_service = async () => {
    try {
        const data = await axios.get(base_url + education_level_url);
        return data
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// get education level by id
export const educationlevelbyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + education_level_url + "/" + id);
        return data
    } catch (error) {
        return error
    }
}

// edit education level by id
export const editeducationlevelbyid_service = async (id, formdata) => {
    try {
        const data = await axios.put(base_url + education_level_url + "/" + id, formdata, { headers: headers });
        return data;
    } catch (error) {
        if (!error.response) {
            // Network error
            throw new Error('Network error');
        } else {
            // HTTP status code is not 2xx
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}



// get all university/college
export const getalluniversityservice = async () => {
    try {
        const data = await axios.get(base_url + university_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// add university/college
export const adduniversityservice = async (formbody) => {
    try {
        const data = await axios.post(base_url + university_url, formbody, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// get all language
export const alllanguageservice = async () => {
    try {
        const data = await axios.get(base_url + language_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// post all language
export const postlanguagesservice = async (formdata) => {
    try {
        const data = await axios.post(base_url + language_url, formdata, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// get language by id
export const languagebyid = async (id) => {
    if (adminbearerToken) {
        try {
            const data = await axios.get(base_url + language_url + "/" + id, { headers: headers });
            return data
        } catch (error) {
            const status = error.response.status;
            if (status === 401) {
                throw new Error('Unauthorized: Please log in.');
            } else if (status === 403) {
                throw new Error('Forbidden: Not Permitted');
            } else if (status === 404) {
                throw new Error('Not Found');
            } else if (status === 500) {
                throw new Error('Server Error: Please try again later.');
            } else {
                // Other errors
                throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
            }
        }
    }
}


// edit language by id
export const updatelanguagebyid_service = async (id, formdata) => {
    try {
        const data = await axios.put(base_url + language_url + "/" + id, formdata, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}


// get all currency
export const getallcurrencyservice = async () => {
    try {
        const data = axios.get(base_url + currency_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// post all currency
export const postcurrencyservice = async (formbody) => {
    try {
        const data = await axios.post(base_url + currency_url, formbody, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// currncy by id
export const getcurrencybyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + currency_url + "/" + id, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// update currecy by id
export const updatecurencybyid_service = async (id, formdata) => {
    try {
        const data = await axios.put(base_url + currency_url + "/" + id, formdata, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// all degree
export const alldegree_service = async () => {
    try {
        const data = await axios.get(base_url + degree_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// add degree
export const addDegreebyEducationLevel_service = async () => {
    try {
        const data = await axios.get(base_url + degree_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const addDegree_service = async (formdata) => {
    const formbody = {
        education_level_id: formdata.education.value,
        name: formdata.name
    }
    try {
        const data = await axios.post(base_url + degree_url, formbody, { headers: headers })
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// Industry

export const addIndustry_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + industry_url, formdata, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// all industry
export const allIndustries_service = async () => {
    try {
        const data = await axios.get(base_url + industry_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

//add job role
export const addjobrole_service = async (formdata) => {
    const formbody = {
        industry_id: formdata.industry.value,
        name: formdata.name
    }
    try {
        const data = await axios.post(base_url + job_role_url, formbody, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// all job role
export const alljobrole_service = async () => {
    try {
        const data = await axios.get(base_url + job_role_url);
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

//add skills
export const addskill_service = async (formdata) => {
    const formbody = {
        jobrole_id: formdata.jobrole.value,
        name: formdata.name
    }
    try {
        const data = await axios.post(base_url + skills_url, formbody, { headers: headers })
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// all skills
export const allskills_service = async () => {
    try {
        const data = await axios.get(base_url + skills_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

//add perks
export const addperk_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + perks_url, formdata, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// all perk
export const allperk_service = async () => {
    try {
        const data = await axios.get(base_url + perks_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

//add work type
export const addworktype_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + worktype_url, formdata, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const allworktype_service = async () => {
    try {
        const data = await axios.get(base_url + worktype_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// job type
export const addjobtype_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + jobtype_url, formdata, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const alljobtype_service = async () => {
    try {
        const data = await axios.get(base_url + jobtype_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// job shift
export const addjobshift_service = async (formdata) => {
    try {
        const data = await axios.post(base_url + jobshift_url, formdata, { headers: headers });
        return data
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const alljobshift_service = async () => {
    try {
        const data = await axios.get(base_url + jobshift_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

// job plans
export const alljobplan_service = async () => {
    try {
        const data = await axios.get(base_url + jobplan_url);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const addjobplan_service = async (formdata) => {
    const body = {
        title: formdata.title,
        description: formdata.description,
        validity: formdata.validity,
        price: formdata.price
    }

    try {
        const data = await axios.post(base_url + jobplan_url, body, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const jobplanbyid_service = async (id) => {
    try {
        const data = await axios.get(base_url + jobplan_url + '/' + id);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}

export const updatejobplan_service = async (formdata, id) => {
    const body = {
        title: formdata.title,
        description: formdata.description,
        validity: formdata.validity,
        price: formdata.price
    }
    try {
        const data = await axios.put(base_url + jobplan_url + '/' + id, body, { headers: headers });
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}


// buy premium
export const buy_premium_service = async (map) => {
    // const formdata = {
    //     candidate_id: localStorage.getItem('candidate_id'),
    //     user_type:1
    // }
    try {
        const data = await axios.post(base_url + buy_premium_url, map);
        return data;
    } catch (error) {
        const status = error.response.status;
        if (status === 401) {
            throw new Error('Unauthorized: Please log in.');
        } else if (status === 403) {
            throw new Error('Forbidden: Not Permitted');
        } else if (status === 404) {
            throw new Error('Not Found');
        } else if (status === 500) {
            throw new Error('Server Error: Please try again later.');
        } else {
            // Other errors
            throw new Error(`An error occurred: ${error.response.data.message || error.message}`);
        }
    }
}