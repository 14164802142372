import React,{useEffect,useState} from 'react';
import {setResumeTemplateData,bindEdit} from '../../functional_lib/common_lib';
import { public_url } from '../../ApiUrl';

export function Comp_Resm_2_New(props){
  const [resumeData,setResumeData]=useState({});
  useEffect(()=>{
      if(typeof props.setProfile.candidate_master!=="undefined"){
          setResumeData(setResumeTemplateData(props.setProfile));
          setTimeout(() => {
            bindEdit();
          }, 1000);
        }
    },[props.setProfile])

    return(<div class="newresumetemplate01">
      {typeof resumeData.basic!=="undefined"?
      <div class="resume row mx-auto">
          <div class="left-section col-4">
              {/* <img src="photo.jpg" alt="Profile Picture"/> */}
              {typeof resumeData.basic.profile_pic!=="undefined"?
                  <img src={public_url+resumeData.basic.profile_pic} alt="" width="90%"/>:''
                  }
              <div class="namedivresume">
                  <h2>{resumeData.basic.first_name} {resumeData.basic.last_name}</h2>
              </div>
              
              <div class="basic-info">
                  <p>
                      <strong>Date of Birth</strong> <br/>
                      {resumeData.basic.dob}
                  </p>
                  <p>
                      <strong>Gender</strong> <br/>
                      {resumeData.basic.gender}
                  </p>
                  <p><strong>Mobile Number</strong> <br/>
                       {resumeData.contact.mobile}</p>
                  <p><strong>Email:</strong> <br/> {resumeData.contact.email}</p>
                  <p><strong>Address:</strong> <br/> {resumeData.contact.address}</p>
                  <p><strong>Languages:</strong> <br/> 
                  {resumeData.language.map((item,index)=>{
            
            return <span class="phone-val">
              {" "+item}{(index<resumeData.language.length-1)?",":''}
            </span>
          })}
                  </p>
              <p>
                  <strong>Skills</strong><br/>
                  {resumeData.skills.map((item,index)=>{
                    return <>
                    {item}<br/>
                    </>
                  })}
              </p>
              </div>
          </div>
          <div class="right-section col-8">
              <div class="heading-sectionresume">
                 <h2 class="editable" e_type="text" e_key="f" e_id="ft">Career Objective</h2> 
              </div>
              <div class="careerobjectiveparagraph">
                  <p class="editable" e_type="text" e_key="f" e_id="ft">To secure a challenging position in a reputable organization to expand my learnings, knowledge, and skills. As a recent graduate with a degree in Computer Science, I am eager to apply my theoretical knowledge and practical skills in a dynamic and challenging work environment. I aim to contribute to innovative projects, grow professionally, and make a meaningful impact in the field of software development.</p>
                  </div>
              
  
                 
              <div class="heading-sectionresume"><h2>Education</h2>
              {resumeData.education.map((item,index)=>{
                      return <>
                      <p><strong>{item["degree"]}</strong> <br/>
                      {item["university"]} <br/>
                      Duration: {item["duration"]}</p>
                      </>
                    })}
                  
              </div>
              
              
              <div class="heading-sectionresume"><h2>Experience</h2>
              {resumeData.experience.map((item,index)=>{
                      return <>
                      <p>{item["company_name"]} <br/> {item["jobrole_name"]}</p>
                      <p>Duration: {item["duration"]}</p>
                      </>
                    })}
              </div>
              
              
              
              <div class="heading-sectionresume"><h2>Job Preference</h2>
              {resumeData.preference.map((item,index)=>{
                return <>
               <p>Work Type: {item["work"]}</p>
                    <p>Timing: {item["job_time"]}</p>
                    <p>Job Shift: {item["job_shift"]}</p>
                    <p>Pref. City: {item["city_name"]}</p>
                </>
              })}
              </div>
              
              
              <div class="heading-sectionresume"><h2 class="editable" e_type="text" e_key="f" e_id="ft">Declaration</h2>
                  <p class="editable" e_type="text" e_key="f" e_id="ft">I hereby declare that the information provided above is true and correct to the best of my knowledge and belief. I understand that any false information or misrepresentation may lead to the rejection of my application or termination of employment.</p>
              </div>
              
              
  
              <p class="signature">Signature</p>
          </div>
      </div>
  :''}
  </div>)

}
export function Comp_Resm_2(props){
  const [resumeData,setResumeData]=useState({});
  useEffect(()=>{
      if(typeof props.setProfile.candidate_master!=="undefined"){
          setResumeData(setResumeTemplateData(props.setProfile));
          setTimeout(() => {
            bindEdit();
          }, 1000);
        }
    },[props.setProfile])

    return(<div class="seventhtemplate">
      {typeof resumeData.basic!=="undefined"?
        <div class="paper">
          <div class="paper2">
            <div class="contact" style={{"width":"10%"}}>
              {/* <ul>
                <li><img src="https://i.postimg.cc/VL1v2bLL/icon-marker.png" alt="" width="27px"/></li>
                <li><img src="https://i.postimg.cc/fRC98fcN/icon-phone.png" alt="" width="27px"/></li>
                <li><img src="https://i.postimg.cc/g0GxRXQT/icon-email.png" alt="" width="27px"/></li>
                <li><img src="https://i.postimg.cc/VNwCRHr4/icon-earth.png" alt="" width="27px"/></li>
              </ul> */}
              <h3 class="grey">Resume</h3>
            </div>
            <div class="content" style={{"width":"90%"}}>
              <div class="left">
              <div class="pic">
                  {typeof resumeData.basic.profile_pic!=="undefined"?
                  <img src={public_url+resumeData.basic.profile_pic} alt="" width="90%"/>:''
                  }
                  
                  {/* <div class="colorbox"></div> */}
                  {/* <div class="colorbox2"></div> */}
                   
                </div>
             
                <h2>{resumeData.basic.first_name} {resumeData.basic.last_name}</h2>
                <h4>DOB</h4>
                <p>{resumeData.basic["dob"]}</p>
                
                <h4>Phone</h4>
                <p>{resumeData.contact.mobile}</p>
                <h4>Email</h4>
                <p>{resumeData.contact.email}</p>
                <h4>Address</h4>
                <p>{resumeData.contact.address}</p>
                {resumeData.language.length>0?<h4>Language</h4>:''}
              <p>
                 {resumeData.language.map((item,index)=>{
                    return <>{" "+item}{(index<resumeData.language.length-1)?",":''}</>
                })} 
              </p>
                
                {/* <h4>Website</h4>
                <p>www.yourweb.com</p> */}

                
<div class="skill">
                  <h3 style={{"display":"inline-block"}}>SKILLS</h3>
                  <p class="skillgroup11">
                    {resumeData.skills.map((item,index)=>{
                      return  <div class="software11">
                      <h4>{item}</h4>
                    </div>
                    })}
                  </p>
                </div>

                <div class="skill">
                  <h3 style={{"display":"inline-block"}}>Job preference</h3>
                  <p class="skillgroup11">
                  {resumeData.preference.map((item,index)=>{
                      return  <div class="software11">
                      <h4><b>Work :</b>{item["work"]}</h4>
                      <h4><b>Timing :</b>{item["job_time"]}</h4>
                      <h4><b>Job Shift :</b>{item["job_shift"]}</h4>
                      <h4><b>Pref. City :</b>{item["city_name"]}</h4>
                    </div>
                    })}
                  </p>
                </div>

              </div>

              <div class="right">
              {/* <h2>for name</h2> */}
                <h3>PROFILE</h3>
                <p class="editable" e_type="textarea" e_key="f" e_id="ft" style={{"text-align":"justify","color":"#838385"}}>Highly skilled and motivated Web Developer with over 6 years of experience in designing, developing, and maintaining responsive and user-friendly websites. </p>
                <h3 class="center">EXPERIENCE</h3>
                {resumeData.experience.map((item,index)=>{
                  return  <div class="year">
                  <h4>{item["company_name"]}</h4>
                  <div class="info grey">{item["jobrole_name"]}</div>
                  <div class="info grey">{item["duration"]}</div>
                </div>
                })}


<h3 class="center">EDUCATION</h3>
                {resumeData.education.map((item,index)=>{
                  return  <div class="year">
                  <h4>{item["degree"]}</h4>
                  <div class="info grey">{item["university"]}</div>
                  <div class="info grey">{item["duration"]}</div>
                </div>
                })}
                
              </div>
            </div>
            {/* <div class="greybox"></div> */}
          </div>
        </div>
        :''}
      </div>)
}
export default Comp_Resm_2;