import React, { useEffect, useState } from 'react'
import JobCard from '../component/JobCard'
import { jobsdata } from '../../JsonData'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { applyjob_service, jobdetailbyslug_sevice, savejob_service } from '../../ApiServices'
import { toast } from 'react-toastify'

const JobDetail = () => {

    const { slug } = useParams();
    const [jobDetails, setJobDetails] = useState(null);
    const { pathname } = useLocation();
    const [forceUpdate, setforceUpdate] = useState(false);
    const [jobApplied, setjobApplied] = useState(false);
    const [jobSaved, setjobSaved] = useState(false);
    const [relatedJob, setrelatedJob] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        // Fetch job details based on the slug
        setJobDetails(null);
        const fetchJobDetails = () => {
            jobdetailbyslug_sevice(slug).then((res) => {
                console.log(res.data);
                setJobDetails(res.data.job)
                setjobSaved(res.data.job_saved);
                setjobApplied(res.data.job_applied);
                setrelatedJob(res.data.relatedJob);
            }).catch((error) => {

            })
        };

        fetchJobDetails();

        if (forceUpdate) {
            setforceUpdate(false)
        }

    }, [slug, forceUpdate]);

    useEffect(() => {
        setforceUpdate(true)
    }, [pathname])



    const applyjob = () => {
        if (localStorage.getItem('candidate_id')) {
            applyjob_service(jobDetails.id).then((res) => {
                toast('✅' + res.data.message);
            }).catch((error) => {
                toast('❌' + error.message);
            })
        } else {
            navigate('/login');
        }
    }


    const savejob = () => {
        if (localStorage.getItem('candidate_id')) {
            savejob_service(jobDetails.id).then((res) => {
                toast('✅' + res.data.message);
            }).catch((error) => {
                toast('❌' + error.message);
            })
        } else {
            navigate('/login');
        }
    }


    return (
        <>
            {jobDetails ? <>
                <Helmet>
                    <title>
                        {jobDetails.jobrole_name} at {jobDetails.company?.companyname} | EJOBEE"
                    </title>
                    <meta name="description" content={`Explore the details of ${jobDetails.jobrole_name} at ${jobDetails.company.companyname} on eJobee. Find job responsibilities, requirements, and benefits. Apply now and take the next step in your career journey.`} />
                </Helmet>
                <section className='job-detail-bg job-detail'>
                    <div className="container pt-5 pb-5">
                        <div className="row">
                            <div className="col-lg-12 d-flex flex-column justify-content-center gap-2">
                                <div className="info d-flex align-items-center justify-content-between">
                                    <div className="company d-flex gap-2 align-items-center justify-content-between">
                                        <div className="com-profile" style={{ width: '45px' }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
                                                <rect width="32" height="32" fill="#E8E7EA" rx="4"></rect>
                                                <path fill="#8C8594" d="M4 5.37C4 4.612 4.613 4 5.37 4h13.26c.757 0 1.37.613 1.37 1.37v22.493a.137.137 0 0 1-.137.137H4.137A.137.137 0 0 1 4 27.863V5.37Z"></path>
                                                <path fill="#E8E7EA" d="M13.5 21h-3a.5.5 0 0 0-.5.5V28h4v-6.5a.5.5 0 0 0-.5-.5Z"></path>
                                                <rect width="3" height="3" x="14" y="16" fill="#E8E7EA" rx="0.4"></rect>
                                                <rect width="3" height="3" x="7" y="16" fill="#E8E7EA" rx="0.4"></rect>
                                                <rect width="3" height="3" x="14" y="11" fill="#E8E7EA" rx="0.4"></rect>
                                                <rect width="3" height="3" x="7" y="11" fill="#E8E7EA" rx="0.4"></rect>
                                                <rect width="3" height="3" x="14" y="6" fill="#E8E7EA" rx="0.4"></rect>
                                                <rect width="3" height="3" x="7" y="6" fill="#E8E7EA" rx="0.4"></rect>
                                                <path fill="#D1CED4" d="M20 14.886c0-.49.396-.886.886-.886h6.228c.49 0 .886.396.886.886V27.91c0 .05-.04.089-.089.089H20.09a.089.089 0 0 1-.089-.089V14.886Z"></path>
                                                <path fill="#fff" d="M22.5 17.4c0-.22.18-.4.4-.4h2.2c.22 0 .4.18.4.4v2.2a.4.4 0 0 1-.4.4h-2.2a.4.4 0 0 1-.4-.4v-2.2ZM22.5 22.4c0-.22.18-.4.4-.4h2.2c.22 0 .4.18.4.4v2.2a.4.4 0 0 1-.4.4h-2.2a.4.4 0 0 1-.4-.4v-2.2Z"></path>
                                            </svg>
                                        </div>
                                        <div>
                                            <p className='fw-bold mb-0 fs-3'>{jobDetails.jobrole_name}</p>
                                            <p className='mb-0'>{jobDetails.company.companyname}</p>
                                        </div>
                                    </div>
                                    <div className="btns d-flex gap-2">
                                        {
                                            jobSaved == true ? <div className="btn btn-light">Saved</div> : <div onClick={savejob} className="btn btn-light">Save Job</div>
                                        }
                                        {
                                            jobApplied == true ? <div className="btn btn-light">Applied</div> : <div onClick={applyjob} className="btn btn-light">Apply Now</div>
                                        }
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src='/assets/images/location_img.png' style={{ width: "20px", height: "20px", filter: 'invert(100%)' }} />
                                    <p className='mb-0'>{jobDetails.office_address} {jobDetails.city_name}</p>
                                </div>
                                <div className='d-flex align-items-center gap-1'>
                                    <img src='/assets/images/wallet_img.png' style={{ width: "20px", height: "20px", filter: 'invert(100%)' }} />
                                    <p className='mb-0'> ₹{" " + jobDetails.min_salary} - {jobDetails.max_salary}</p>
                                </div>
                                <div className="d-flex gap-2 flex-wrap">
                                    {
                                        <div className='card' style={{ fontSize: '13px', padding: '0px 8px' }}>
                                            {jobDetails.minage + "-" + jobDetails.maxage + " Years"}
                                        </div>
                                    }
                                    {
                                        jobDetails.work_type ? <div className='card' style={{ fontSize: '13px', padding: '0px 8px' }}>
                                            {jobDetails.work_type.name}
                                        </div> : ''
                                    }
                                    {
                                        jobDetails.job_type ? <div className='card' style={{ fontSize: '13px', padding: '0px 8px' }}>
                                            {jobDetails.job_type.name}
                                        </div> : ''
                                    }
                                    {
                                        jobDetails.is_night == true ? <div className='card' style={{ fontSize: '13px', padding: '0px 8px' }}>
                                            Night Shift
                                        </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className='pt-5'>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                {
                                    JSON.parse(jobDetails.skills).length == 0 ?
                                        "" : <div>
                                            <h4>Skills</h4>
                                            <div className='d-flex gap-4 flex-wrap mt-3'>
                                                {
                                                    JSON.parse(jobDetails.skills).map((v) => {
                                                        return (
                                                            <div>
                                                                ✔️ {v.label}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                }
                                {
                                    jobDetails.perks ? 
                                    jobDetails.perks.length == 0 ? "" :
                                        <div className='mt-4'>
                                            <h4>perks</h4>
                                            <div className='d-flex gap-4 flex-wrap mt-3'>
                                                {
                                                    jobDetails.perks.map((v) => {
                                                        return (
                                                            <div>
                                                                ✔️ {v.label}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div> : ""
                                }
                                <div className='mt-4 d-flex flex-column gap-3'>
                                    <h4>Job Detail</h4>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex gap-1'>
                                            <img src='/assets/images/education_img.png' style={{ width: "20px", height: "20px" }} />
                                            {
                                                jobDetails.education_level.id > 2 ?
                                                    <div className='d-flex flex-column gap-1'>
                                                        <p className='mb-0'>Diploma/Degree</p>
                                                        <p className='mb-0'>{jobDetails.degree_name}</p>
                                                    </div> : <div className='d-flex flex-column gap-1'>
                                                        <p className='mb-0'>Education</p>
                                                        <p className='mb-0'>{jobDetails.education_level.name}</p>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex gap-1'>
                                            <img src='/assets/images/experience_img.png' style={{ width: "20px", height: "20px" }} />
                                            <div className='d-flex flex-column gap-1'>
                                                <p className='mb-0'>Experience</p>
                                                {
                                                    jobDetails.experience == "experienced" ? jobDetails.exp_year + " Years" : <p className='mb-0'>{jobDetails.experience}</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                    <div className='d-flex gap-1'>
                                            <img src='/assets/images/gender_img.png' style={{ width: "20px", height: "20px" }} />
                                            <div className='d-flex flex-column gap-1'>
                                                <p className='mb-0'>Gender</p>
                                                <p className='mb-0'>{jobDetails.gender}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex gap-1'>
                                            <img src='/assets/images/language_img.png' style={{ width: "20px", height: "20px" }} />
                                            <div className='d-flex flex-column gap-1'>
                                                <p className='mb-0'>English Level</p>
                                                <p className='mb-0'>{jobDetails.english_level}</p>
                                            </div>
                                        </div>

                                        
                                    </div>
                                    <div>
                                        {
                                            jobDetails.is_fees == 'true' ? <div className='d-flex gap-1'>
                                                <img src='/assets/images/location_img.png' style={{ width: "20px", height: "20px" }} />
                                                <div className='d-flex flex-column gap-1'>
                                                    <p className='mb-0'>Fees</p>
                                                    <p className='mb-0'>{jobDetails.fee_amount}</p>
                                                </div>
                                            </div> : ""
                                        }
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between'>
                                        <div className='d-flex gap-1'>
                                            <img src='/assets/images/location_img.png' style={{ width: "20px", height: "20px" }} />
                                            <div className='d-flex flex-column gap-1'>
                                                <p className='mb-0'>Address</p>
                                                <p className='mb-0'>{jobDetails.office_address}</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-6 mt-4">
                                <div dangerouslySetInnerHTML={{ __html: jobDetails.job_description }} />
                            </div>
                        </div>
                    </div>
                </section>
            </> : <section>
                <div style={{ height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div class="loading-loader"></div>
                </div>
            </section>}

            <section>
                <div className="container">
                    <div className="section-title">
                        <h4>Related Jobs</h4>
                    </div>
                    <div className="row mt-4 job-row">
                        {
                            relatedJob.map((v) => {
                                return (
                                    <JobCard jobtitle={v.jobrole_name} companyname={v.company.companyname} location={v.office_address+" "+v.city_name} minsalary={v.min_salary} maxsalary={v.max_salary} worktype={v.work_type.name} employementtype={v.job_type.name} slug={v.slug} />
                                )
                            })
                        }
                    </div>
                </div>
            </section>

        </>
    )
}

export default JobDetail